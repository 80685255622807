import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { columnDefs } from 'components/AgGrid/AgGrid';
import { useCallback } from 'react';
import {
    Categories,
    MarketTrendsDateRanges,
    categoriesToResponceCategoryField,
    categoriesToResponceLidField,
} from 'utils';
import { MarketTrend } from '../../types/market';
import { ETFFlowsToAssetThemesAndFactorsData, ETFPerformanceThemesAndFactorsData } from '../../types/research';
import { EtfsOverlay } from './EtfsOverlay';
import { DrillDownProps } from './types';
import { Link, getNextLevelCategory } from './utils';

export function getCompanyDetailsLinkRenderer(type: 'cfraId' | 'ticker/exchange') {
    return (props: ICellRendererParams) => {
        const value = props.valueFormatted || props.value;
        if (type === 'cfraId' && !props.data.cfra_id && !props.data.id && !props.data.firstbridge_id) return value;
        if (type === 'ticker/exchange' && !props.data.composite_ticker && !props.data.exchange_code) return value;
        return (
            <a
                href={
                    type === 'cfraId'
                        ? `/etf/cfra-id/${props.data.cfra_id || props.data.id || props.data.firstbridge_id}`
                        : `/etf/ticker/${props.data.composite_ticker}/exchange/${props.data.exchange_code}`
                }
                target={'_blank'}
                rel='noreferrer'
                className='company-details-url'>
                {value}
            </a>
        );
    };
}

export function getDrillDownRenderer(
    setDrillDownProps: (value: DrillDownProps) => void,
    setSelectedCategory: (value: Categories) => void,
) {
    return (props: ICellRendererParams) => {
        const value = props.valueFormatted || props.value;
        const columnDef = props?.colDef as ColDef<columnDefs>;
        if (
            typeof value !== 'string' ||
            !columnDef.headerName ||
            ![Categories.AssetClass as string, Categories.CategoryOne as string].includes(columnDef.headerName)
        ) {
            return value;
        }
        return (
            <Link
                value={value}
                handleOpen={() => {
                    setSelectedCategory(getNextLevelCategory(columnDef.headerName as Categories));
                    setDrillDownProps({
                        categoryOne: (columnDef.headerName as Categories) === Categories.CategoryOne ? value : '',
                        assetClass: props.data['asset_class'],
                    });
                }}
            />
        );
    };
}

export const getEtfsListOverlayRenderer =
    (label: MarketTrend, dateRange: MarketTrendsDateRanges, category: Categories) =>
    (props: ICellRendererParams<ETFFlowsToAssetThemesAndFactorsData | ETFPerformanceThemesAndFactorsData>) => {
        const value = props.valueFormatted || props.value;

        if (!props.data) {
            return <>{value}</>;
        }

        const lid = props.data[categoriesToResponceLidField[category] as keyof typeof props.data];
        const categoryName = props.data[categoriesToResponceCategoryField[category] as keyof typeof props.data];

        if (!lid || typeof lid !== 'number' || !categoryName) {
            return <>{value}</>;
        }

        const getOpenModalButton = useCallback(
            (handleOpen: () => void) => <Link value={value} handleOpen={handleOpen} />,
            [value],
        );

        return (
            <EtfsOverlay
                categoryLevel={category}
                categoryName={String(categoryName)}
                lid={lid}
                getOpenComponent={getOpenModalButton}
                label={label}
                dateRange={dateRange}
            />
        );
    };
