import { Box, SxProps } from '@mui/system';
import { fontFamilies, getMomentObjectFrom } from 'utils';

type DataAsOfDateProps = {
    date: string;
    styles?: SxProps;
};

export function DataAsOfDate(props: DataAsOfDateProps) {
    const { date, styles } = props;

    const defaultStyles: SxProps = {
        fontFamily: fontFamilies.GraphikRegular,
        color: 'rgb(118, 129, 140)',
        fontSize: '11px',
        position: 'absolute',
        right: '18px',
        bottom: '10px'
    };

    if (!date) {
        return null
    }

    const formattedAsOfDate = getMomentObjectFrom(date).format('MM/DD/YYYY');

    return (
        <Box component='div' sx={{ ...defaultStyles, ...styles }}>
            {`Data as of ${formattedAsOfDate}`}
        </Box>
    );
}
