import { SelectChangeEvent, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CFRASelect from 'components/CFRASelect';
import { BarChartWithBigLabels } from 'components/Chart/BarWithBigLabels';
import { ChartTabs } from 'components/Chart/ChartTabs';
import { getDefaultFontStyle, getTooltipHTML } from 'components/Chart/Options';
import { NumberOrNull } from 'components/Chart/types';
import Tooltip from 'components/dataDisplay/Tooltip';
import { HorizontalPanel, ItemHeader } from 'components/layout';
import Grid from 'components/layout/Grid';
import { tooltipThemeV2 } from 'components/themes/theme';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Series, SeriesClickCallbackFunction, SeriesClickEventObject } from 'highcharts/highstock';
import { queryClient } from 'lib/react-query-client';
import React, { ReactNode } from 'react';
import { QueryClientProvider, UseQueryResult } from 'react-query';
import { NavigateFunction } from 'react-router-dom';
import {
    Categories,
    MarketTrendsDateRanges,
    ValueTypes,
    categoriesToResponceCategoryField,
    categoriesToResponceLidField,
    fontFamilies,
    getMomentObjectFrom,
} from 'utils';
import { pathMapping } from '../../../routes/MarketTrendsRoutes';
import { MarketTrend } from '../../../types/market';
import { ETFFlowsToAssetThemesAndFactorsData, ETFPerformanceThemesAndFactorsData } from '../../../types/research';
import { CategoriesInfo } from '../../shared/CategoriesInfo';
import { EtfsOverlay } from '../../shared/EtfsOverlay';
import { getNextLevelCategory } from '../../shared/utils';
import { MarketTrendsChartsTopPanel } from './MarketTrendsChartsTopPanel';
import { renderWithContainer } from './utils';

type SubChartProps = {
    chartHeader: string;
    categories: Array<string>;
    categoriesData: Array<NumberOrNull>;
    asOfDates: Array<string>;
    tickers?: Array<string>;
    reverseYaxis?: boolean;
    chartRefObject?: React.RefObject<HighchartsReact.RefObject>;
    chartRefSetter: (value: any) => void;
    columnsToFormatting: Map<string, ValueTypes>;
};

type ThemesAndFactorsChartProps = {
    title: string;
    subHeader: string | ReactNode;
    exportFileName: string;
    exportHeaders?: Array<string>;
    detailsPathName?: MarketTrend;
    subChartsProps: Array<SubChartProps>;
    tooltipFormatterValuePrefix: string;
    columnHeaderFormatter: (item: any, key: any) => string;
    dateRangesDropdownConfig?: {
        selectedDateRange: MarketTrendsDateRanges;
        handleChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
    };
    categoriesPanelConfig?: {
        selectedCategory: Categories;
        handleChange: (event: React.SyntheticEvent<Element, Event>, newValue: number) => void;
    };
    onBarClick?: SeriesClickCallbackFunction;
};

const PaleItemSubHeader = styled(ItemHeader)(({ theme }) => ({
    ...getDefaultFontStyle(15),
}));

const ChartHeader = styled(Paper)(({ theme }) => ({
    fontFamily: fontFamilies.GraphikMedium,
    lineHeight: 1,
    fontSize: '15px',
    color: '#57626a',
    borderBottom: '1px solid #74828D',
    borderRadius: '0px',
    paddingBottom: '0px',
}));

export const xAxisColumnName = 'Company name';

export function getColumnHeaderFormatter(yAxisColumnName: string) {
    return function (item: any, key: any): string {
        if (item instanceof Highcharts.Axis && item.isXAxis) return xAxisColumnName;
        return yAxisColumnName;
    };
}

export function ThemesAndFactorsChart({
    title,
    subHeader,
    subChartsProps,
    exportFileName,
    exportHeaders = [],
    tooltipFormatterValuePrefix,
    columnHeaderFormatter,
    detailsPathName,
    onBarClick,
    dateRangesDropdownConfig,
    categoriesPanelConfig
}: ThemesAndFactorsChartProps) {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const handleTooltip = (state: boolean) => setTooltipOpen(state);

    const chartWidth = subChartsProps.length > 1 ? 6 : 12;

    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <MarketTrendsChartsTopPanel
                title={title}
                detailsPathName={detailsPathName}
                multipleExportProps={{
                    subChartsProps: subChartsProps.map((element) => {
                        return {
                            chartRefObject: element.chartRefObject,
                            chartHeader: element.chartHeader,
                            columnsToFormatting: element.columnsToFormatting,
                        };
                    }),
                    exportFileName: exportFileName,
                    exportHeaders: exportHeaders
                }}
            />
            <HorizontalPanel>
                <PaleItemSubHeader>{subHeader}</PaleItemSubHeader>
            </HorizontalPanel>
            {dateRangesDropdownConfig && categoriesPanelConfig ? (
                <HorizontalPanel container sx={{ paddingBottom: '24px' }}>
                    <Grid item>
                        <Grid container>
                            <ChartTabs
                                tabs={Object.values(Categories)}
                                currentActiveTab={Object.values(Categories).indexOf(
                                    categoriesPanelConfig.selectedCategory,
                                )}
                                handleTabChange={categoriesPanelConfig.handleChange}
                            />
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <CategoriesInfo />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: 'right' }}>
                        <ThemeProvider theme={tooltipThemeV2}>
                            <Tooltip title='Select Period' open={tooltipOpen}>
                                <span>
                                    <CFRASelect
                                        mouseHandler={handleTooltip}
                                        selectItems={Object.values(MarketTrendsDateRanges)}
                                        currentSelection={dateRangesDropdownConfig.selectedDateRange}
                                        handleSelectionChange={dateRangesDropdownConfig.handleChange}
                                    />
                                </span>
                            </Tooltip>
                        </ThemeProvider>
                    </Grid>
                </HorizontalPanel>
            ) : (
                ''
            )}
            <Grid container columnSpacing={3.5} sx={{ maxWidth: '1100px' }}>
                {React.Children.toArray(
                    subChartsProps.map((value) => (
                        <Grid item xs={chartWidth}>
                            <ChartHeader sx={{ paddingBottom: '12px' }}>{value.chartHeader}</ChartHeader>
                        </Grid>
                    )),
                )}
                {React.Children.toArray(
                    subChartsProps.map((value) => {
                        function tooltipFormatter(
                            this: Highcharts.TooltipFormatterContextObject & { category?: string },
                        ) {
                            // category missed in TooltipFormatterContextObject
                            const asOfDate = value.asOfDates[value.categories.indexOf(this.category as string)];
                            return getTooltipHTML(this.category, [
                                value.tickers
                                    ? `Ticker: ${value.tickers[value.categories.indexOf(this.category as string)]}`
                                    : '',
                                `${tooltipFormatterValuePrefix} ${this.y as number}%`,
                                `As of date: ${getMomentObjectFrom(asOfDate).format('MM/DD/YYYY')}`,
                            ]);
                        }

                        return (
                            <Grid item xs={chartWidth} sx={{ paddingTop: '18px' }}>
                                <BarChartWithBigLabels
                                    categories={value.categories}
                                    categoriesData={value.categoriesData}
                                    columnHeaderFormatter={columnHeaderFormatter}
                                    setChartRef={value.chartRefSetter}
                                    reverseYaxis={value.reverseYaxis}
                                    tooltipFormatter={tooltipFormatter}
                                    onBarClick={onBarClick}
                                />
                            </Grid>
                        );
                    }),
                )}
            </Grid>
        </Box>
    );
}

export type onBarClickProps<T> = {
    selectedCategory: Categories;
    selectedDateRange: MarketTrendsDateRanges;
    chartData: UseQueryResult<T[]>[];
    detailsPathName: MarketTrend;
    navigate: NavigateFunction;
    categoriesContainTickers?: boolean;
};

export function onBarClick<T extends ETFFlowsToAssetThemesAndFactorsData | ETFPerformanceThemesAndFactorsData>({
    selectedCategory,
    selectedDateRange,
    chartData,
    detailsPathName,
    navigate,
    categoriesContainTickers = false,
}: onBarClickProps<T>) {
    return function (this: Series, event: SeriesClickEventObject) {
        if (typeof event.point.category !== 'string') {
            return;
        }

        const cutOffTicker = (category: string) => {
            if (!categoriesContainTickers) {
                return category;
            }
            const indexOfTikerOpenParenthesis = category.lastIndexOf('(');
            return indexOfTikerOpenParenthesis > -1
                ? category.substring(0, indexOfTikerOpenParenthesis).trim()
                : category;
        };

        const pointCategory = cutOffTicker(String(event.point.category));

        const pointCategoryData = chartData
            .reduce((accumulator, currentValue) => {
                return accumulator.concat(currentValue.data || ([] as Array<T>));
            }, [] as Array<T>)
            .find((categoryData) => {
                const categoryName =
                    categoryData[categoriesToResponceCategoryField[selectedCategory] as keyof typeof categoryData];
                return categoryName === pointCategory;
            });

        if (!pointCategoryData) {
            return;
        }

        if (selectedCategory === Categories.CategoryTwo) {
            const lid =
                pointCategoryData[categoriesToResponceLidField[selectedCategory] as keyof typeof pointCategoryData];

            if (!lid) {
                return;
            }

            // used renderWithContainer only because there is no way to render the component inside the main root, don't use it if there is available way to render the component inside the main root
            renderWithContainer('cfra-overlay-modal-container', (unmountCallback: () => void) => (
                <QueryClientProvider client={queryClient}>
                    <EtfsOverlay
                        categoryLevel={selectedCategory}
                        categoryName={pointCategory}
                        lid={Number(lid)}
                        label={detailsPathName}
                        dateRange={selectedDateRange}
                        callbackOnClose={unmountCallback}
                        maxGridContainerHeightPercentage={90} // max modal height in percentage
                    />
                </QueryClientProvider>
            ));
            return;
        }

        navigate(pathMapping[detailsPathName], {
            state: {
                drillDownProps: {
                    categoryOne: selectedCategory === Categories.CategoryOne ? pointCategory : '',
                    assetClass: pointCategoryData['asset_class'],
                },
                selectedDateRange: selectedDateRange,
                selectedCategory: getNextLevelCategory(selectedCategory),
            },
        });
    };
}
