import Highcharts, {
    SeriesClickCallbackFunction,
    SeriesOptionsType,
    TooltipFormatterContextObject,
} from 'highcharts/highstock';
import { fontWeights } from 'utils/fonts';
import { Chart } from './Chart';
import { getDefaultFontStyle, getToolTipOptions } from './Options';
import { NumberOrNull } from './types';

function getBarRoundingOptions(reverseAxis?: boolean) {
    let { bottom, top } = reverseAxis ? { bottom: '50%', top: '0%' } : { bottom: '0%', top: '50%' };

    return {
        borderRadiusBottomLeft: bottom,
        borderRadiusBottomRight: bottom,
        borderRadiusTopLeft: top,
        borderRadiusTopRight: top,
    };
}

export function BarChartWithBigLabels({
    categories,
    categoriesData,
    setChartRef,
    reverseYaxis,
    columnHeaderFormatter,
    tooltipFormatter,
    onBarClick,
}: {
    categories: Array<string>;
    categoriesData: Array<NumberOrNull>;
    setChartRef: (value: any) => void;
    reverseYaxis?: boolean;
    columnHeaderFormatter: (item: any, key: any) => string;
    tooltipFormatter: (this: TooltipFormatterContextObject) => string;
    onBarClick?: SeriesClickCallbackFunction;
}) {
    const series = [
        {
            type: 'bar',
            color: '#5F9EA0',
            negativeColor: '#d12b10',
            data: categoriesData,
            pointPlacement: 0,
            ...getBarRoundingOptions(reverseYaxis),
        },
    ];

    const options: Highcharts.Options = {
        chart: {
            height: series[0].data.length * 59,
            spacingLeft: 0,
            spacingRight: 0,
            spacingBottom: 0,
            marginTop: 4,
        },
        exporting: {
            csv: {
                columnHeaderFormatter: columnHeaderFormatter,
            },
            enabled: false,
            fallbackToExportServer: false,
            allowHTML: true,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        ...getDefaultFontStyle(),
                        fontWeight: fontWeights.Regular,
                    },
                    padding: 20,
                },
                minPointLength: 2,
                pointWidth: 16,
                events: {
                    click: onBarClick,
                },
            },
        },
        series: series as Array<SeriesOptionsType>,
        title: {
            text: undefined,
        },
        tooltip: {
            ...getToolTipOptions(true, tooltipFormatter),
            outside: true,
            style: { filter: 'drop-shadow(0px 0px 3.68px rgba(0, 0, 0, 0.3))' },
        },
        xAxis: {
            categories: categories,
            labels: {
                align: 'left',
                enabled: true,
                style: {
                    ...getDefaultFontStyle(),
                    whiteSpace: 'normal',
                },
                formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
                    return `<div style="width: ${
                        this.chart.chartWidth
                    }px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${String(this.value)}</div>`;
                },
                useHTML: true,
                x: 0,
                y: -20,
            },
            lineWidth: 0,
            title: {
                text: undefined,
            },
        },
        yAxis: {
            reversed: reverseYaxis,
            title: {
                text: undefined,
            },
            visible: false,
        },
    };

    return <Chart options={options} setChartRef={setChartRef} enableExportMenusAndTitle={false} />;
}
