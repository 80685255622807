import { ThemeProvider } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import ETFModal from 'components/ETFModal';
import { exportAgGrid } from 'components/excelExport/export';
import { overlayModalTheme } from 'components/themes/theme';
import { colDefType } from 'components/types/agGrid';
import { useRef } from 'react';
import { EtfsGridCard, EtfsGridCardProps } from './EtfsGridCard';

type EtfsOverlayProps = {
    categoryLevel: string;
    categoryName: string;
    getOpenComponent?: (handleOpen: () => void) => React.ReactElement;
    callbackOnClose?: () => void;
} & EtfsGridCardProps;

export function EtfsOverlay({
    categoryLevel,
    categoryName,
    getOpenComponent,
    label,
    lid,
    dateRange,
    callbackOnClose,
}: EtfsOverlayProps) {
    const overlayGridRef = useRef<AgGridReact<colDefType>>(null);
    const agGridExportProps = {
        fileName: `${categoryName} ETF List`,
        sheetName: 'ETF List',
        metadata: {
            cardName:categoryLevel + ' ' + categoryName,
        },
        asOfDateField: 'data_returns_flow.as_of_date',
        useMaxAsOfDate: true,
        autoSizeColumns: true
    }

    const overlayExportCallback = () => exportAgGrid(overlayGridRef, agGridExportProps);

    return (
        <ThemeProvider theme={overlayModalTheme}>
            <ETFModal
                title={`${categoryLevel}: ${categoryName}`}
                titleStyle={{ paddingBottom: '12px', color: '#002B5A', fontSize: '18px' }}
                description={'ETFs belonging to the selected category'}
                descriptionStyle={{ paddingBottom: '36px' }}
                titleAndDescriptionContainerStyle={{ padding: '28px', paddingBottom: '0px' }}
                closeButtonFontSize={30}
                getOpenComponent={getOpenComponent}
                exportCallback={overlayExportCallback}
                callbackOnClose={callbackOnClose}>
                <EtfsGridCard
                    categoryLevel={categoryLevel}
                    lid={lid}
                    label={label}
                    ref={overlayGridRef}
                    dateRange={dateRange}
                    maxGridContainerHeightPercentage={90} // max modal height in percentage
                />
            </ETFModal>
        </ThemeProvider>
    );
}
