import { ETFInfo } from 'components/layout';

export function CategoriesInfo({ buttonFontSize = 15 }: { buttonFontSize?: number }) {
    const title = 'About CFRA Categories';
    const description = (
        <>
            CFRA groups funds using our proprietary categorization methodology. In addition to Asset Class, we organize
            funds by:
            <ul style={{ paddingLeft: '14px', marginTop: '0px', marginBottom: '0px' }}>
                <li>
                    <b>CFRA Category:</b> Unique, ETF-specific categories for each major asset class.
                </li>
                <li>
                    <b>CFRA Sub-category:</b> Our most granular taxonomy, featuring funds grouped by strategy,
                    geography, currency, sector, and other fund types.
                </li>
            </ul>
            <a
                href={'pdfs/CFRA_FUNDynamix_Category_Definitions.pdf'}
                target={'_blank'}
                rel={'noreferrer'}
                style={{
                    color: '#007bb8',
                    textDecoration: 'none',
                }}>
                Learn more about CFRA Classifications
            </a>
        </>
    );

    return <ETFInfo title={title} description={description} buttonFontSize={buttonFontSize} />;
}
