import { getAgGridFormatter } from 'components/AgGrid';
import { ChartTabsProps } from 'components/Chart/ChartTabs';
import { ETFButtonsPannelButtonProps, ETFCard } from 'components/layout';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useLocation, useOutletContext } from 'react-router-dom';
import {
    Categories,
    MarketTrendsDateRanges,
    categoriesToAggregateField,
    categoriesToResponceCategoryField,
    dateRangeToFlowToAssetRatioDataPoint,
    dateRangeToNetFlowsDataPoint,
    dateRangeToReturnSplitAndDividendDataPoint,
    marketTrendsDateRangesToDisplayString,
} from 'utils';
import { ValueTypes } from 'utils/valuesFormatter';
import { getAggregationFlows, getAggregationPerformance, getKeyAssetClassesData } from '../api/market';
import { ContextType, MarketTrend, MarketTrendsDetailsProps } from '../types/market';
import { KeyAssetClassesData } from '../types/research';
import {
    tickerDisplayName as keyAssetClassesTickerDisplayName,
    tickerExchanges as keyAssetClassesTickerExchanges,
} from './charts/KeyAssetClasses';
import {
    tickerDisplayName as SectorPerformanceTickerDisplayName,
    tickerExchanges as SectorPerformanceTickerExchanges,
} from './charts/SectorPerformance';
import { AgGirdCard, AgGirdCardTopPanel } from './shared/AgGridCard';
import { getCompanyDetailsLinkRenderer, getDrillDownRenderer, getEtfsListOverlayRenderer } from './shared/renderers';
import { BreadcrumbConfig, DrillDownProps } from './shared/types';
import { NoInformationAvailable, getMarketTrendsDetailsCardDescription, getNextLevelCategory } from './shared/utils';

export type MarketTrendsDetailsLocationState = {
    drillDownProps: DrillDownProps;
    selectedDateRange: MarketTrendsDateRanges;
    selectedCategory: Categories;
};

export function MarketTrendsDetails({ label }: MarketTrendsDetailsProps) {
    const { state }: { state: MarketTrendsDetailsLocationState } = useLocation();

    const [drillDownProps, setDrillDownProps] = useState<DrillDownProps | null>(
        state && state.drillDownProps ? state.drillDownProps : null,
    );

    const [selectedDateRange, setSelectedDateRange] = useState<MarketTrendsDateRanges>(
        state && state.selectedDateRange ? state.selectedDateRange : MarketTrendsDateRanges.ThreeMonth,
    );
    const [selectedCategory, setSelectedCategory] = useState<Categories>(
        state && state.selectedDateRange ? state.selectedCategory : Categories.CategoryOne,
    );

    let dateRangesPanelConfig: ETFButtonsPannelButtonProps[] | undefined = undefined;
    let categoriesPanelConfig: ChartTabsProps | undefined = undefined;

    let columnDefs: any = [];   //TODO: fix this type, should be: let columnDefs: columnDefs = []; 
    let result: UseQueryResult<any[]>;

    const etfsListOverlayRenderer = useMemo(
        () => getEtfsListOverlayRenderer(label, selectedDateRange, selectedCategory),
        [label, selectedDateRange, selectedCategory],
    );
    const companyDetailsLinkRenderer = getCompanyDetailsLinkRenderer('cfraId');
    const drillDownRenderer = getDrillDownRenderer(setDrillDownProps, setSelectedCategory);

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails:
        case MarketTrend.PerformanceDetails:
            const assetClassColumn = {
                headerName: Categories.AssetClass,
                field: 'asset_class',
                cellRenderer: drillDownRenderer,
            };
            const cfraCategoryColumn = {
                headerName: Categories.CategoryOne,
                field: 'cfra_level_1',
                cellRenderer: drillDownRenderer,
            };
            const cfraSubCategoryColumn = {
                headerName: Categories.CategoryTwo,
                field: 'level_2_name',
                cellRenderer: etfsListOverlayRenderer,
            };

            switch (selectedCategory) {
                case Categories.AssetClass:
                    columnDefs.push(assetClassColumn);
                    break;
                case Categories.CategoryOne:
                    columnDefs = columnDefs.concat([assetClassColumn, cfraCategoryColumn]);
                    break;
                case Categories.CategoryTwo:
                    columnDefs = columnDefs.concat([assetClassColumn, cfraCategoryColumn, cfraSubCategoryColumn]);
                    break;
            }

            dateRangesPanelConfig = Object.values(MarketTrendsDateRanges).map((dateRange) => ({
                name: dateRange,
                callback: () => setSelectedDateRange(dateRange as MarketTrendsDateRanges),
                disabled: false,
                isDefault: dateRange === selectedDateRange,
            }));

            categoriesPanelConfig = {
                tabs: Object.values(Categories),
                currentActiveTab: drillDownProps ? false : Object.values(Categories).indexOf(selectedCategory),
                handleTabChange: (event: React.SyntheticEvent, newValue: number) => {
                    if (drillDownProps) setDrillDownProps(null);
                    setSelectedCategory(Object.values(Categories)[newValue]);
                },
            };

            break;
    }

    const performanceColumnsDefs = [
        {
            headerName: 'YTD Return',
            field: 'performance.split_and_dividend_ytd_return',
            valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
            type: 'rightAligned',
            valueType: ValueTypes.Percentage,
        },
        {
            headerName: '1 Month Return',
            field: 'performance.month_1_return',
            valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
            type: 'rightAligned',
            valueType: ValueTypes.Percentage,
        },
        {
            headerName: '3 Month Return',
            field: 'performance.month_3_return',
            valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
            type: 'rightAligned',
            valueType: ValueTypes.Percentage,
        },
        {
            headerName: '1 Year Return',
            field: 'performance.return_split_and_dividend_one_year',
            valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
            type: 'rightAligned',
            valueType: ValueTypes.Percentage,
        },
    ];

    const flowsColumnsDefs = [
        {
            headerName: 'YTD Flows',
            field: 'performance.net_flows_ytd',
            valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
            type: 'rightAligned',
            valueType: ValueTypes.Numeral,
        },
        {
            headerName: '1 Month Flows',
            field: 'performance.net_flows_one_month',
            valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
            type: 'rightAligned',
            valueType: ValueTypes.Numeral,
        },
        {
            headerName: '3 Month Flows',
            field: 'performance.net_flows_three_month',
            valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
            type: 'rightAligned',
            valueType: ValueTypes.Numeral,
        },
        {
            headerName: '1 Year Flows',
            field: 'performance.net_flows_one_year',
            valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
            type: 'rightAligned',
            valueType: ValueTypes.Numeral,
        },
    ];

    const etfNameColumnDef = {
        headerName: 'ETF Name',
        field: 'composite_name',
        cellRenderer: companyDetailsLinkRenderer,
        valueType: ValueTypes.Text,
    };

    const tickerColumnDef = {
        headerName: 'Ticker',
        field: 'composite_ticker',
        cellRenderer: companyDetailsLinkRenderer,
        valueType: ValueTypes.Text,
    };

    const numberOfEtfsColumnDef = {
        headerName: 'Number of ETFs',
        field: 'etf_count',
        type: 'rightAligned',
        flex: 1,
        cellRenderer: etfsListOverlayRenderer,
        valueType: ValueTypes.Integer,
    };

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails: {
            result = getAggregationFlows({
                sortDirection: 'desc',
                top: 1000,
                orderBy: dateRangeToFlowToAssetRatioDataPoint[selectedDateRange],
                aggregateBy: categoriesToAggregateField[selectedCategory],
            });

            columnDefs = columnDefs.concat([
                {
                    headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Flows`,
                    field: dateRangeToNetFlowsDataPoint[selectedDateRange],
                    valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
                    type: 'rightAligned',
                    valueType: ValueTypes.Numeral,
                },
                {
                    headerName: 'Total Assets',
                    field: 'total_net_assets',
                    valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
                    type: 'rightAligned',
                    valueType: ValueTypes.Numeral
                },
                {
                    headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Flows to Asset`,
                    field: dateRangeToFlowToAssetRatioDataPoint[selectedDateRange],
                    sort: 'desc',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                    valueType: ValueTypes.Percentage
                },
                numberOfEtfsColumnDef,
            ]);
            break;
        }
        case MarketTrend.PerformanceDetails: {
            result = getAggregationPerformance({
                sortDirection: 'desc',
                top: 1000,
                orderBy: dateRangeToReturnSplitAndDividendDataPoint[selectedDateRange],
                aggregateBy: categoriesToAggregateField[selectedCategory],
            });

            columnDefs = columnDefs.concat([
                etfNameColumnDef,
                tickerColumnDef,
                {
                    headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Return`,
                    field: dateRangeToReturnSplitAndDividendDataPoint[selectedDateRange],
                    sort: 'desc',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                    valueType: ValueTypes.Percentage
                    
                },
                numberOfEtfsColumnDef,
            ]);
            break;
        }
        case MarketTrend.KeyAssetClassesDetails: {
            result = getKeyAssetClassesData({
                tickerExchanges: keyAssetClassesTickerExchanges,
            });

            columnDefs = [
                {
                    headerName: 'Asset Class',
                    field: 'asset_class',
                    sort: 'asc',
                },
                etfNameColumnDef,
                tickerColumnDef,
            ];
            columnDefs = columnDefs.concat(performanceColumnsDefs);
            columnDefs = columnDefs.concat(flowsColumnsDefs);
            break;
        }
        case MarketTrend.SectorPerformanceDetails: {
            result = getKeyAssetClassesData({
                tickerExchanges: SectorPerformanceTickerExchanges,
            });

            columnDefs = [{ headerName: 'Sector', field: 'sector', sort: 'asc' }, etfNameColumnDef, tickerColumnDef];
            columnDefs = columnDefs.concat(performanceColumnsDefs);
            columnDefs = columnDefs.concat(flowsColumnsDefs);
            break;
        }
        default: {
            throw new Error(`${label} doesn't exist.`);
        }
    }

    const [title, setTitle] = useOutletContext<ContextType>();

    useLayoutEffect(() => {
        setTitle(label);
    }, [setTitle, label]);

    let description = getMarketTrendsDetailsCardDescription(label);

    let breadcrumbsConfig: BreadcrumbConfig[] | undefined = undefined;

    const EtfCardWithTopPanel = useCallback(
        ({ children }: { children: React.ReactNode }) => {
            return (
                <ETFCard containerStyles={{ padding: '0px' }}>
                    <AgGirdCardTopPanel
                        label={label}
                        description={description}
                        dateRangesPanelConfig={dateRangesPanelConfig}
                        categoriesPanelConfig={categoriesPanelConfig}
                        breadcrumbsConfig={breadcrumbsConfig}
                    />
                    {children}
                </ETFCard>
            );
        },
        [label, dateRangesPanelConfig, categoriesPanelConfig, breadcrumbsConfig, description],
    );

    if (result.isLoading || title !== label) {
        return (
            <EtfCardWithTopPanel>
                <ETFCard isLoading={result.isLoading || title !== label} />
            </EtfCardWithTopPanel>
        );
    }

    if (!result.data) {
        return (
            <EtfCardWithTopPanel>
                <NoInformationAvailable sx={{ width: '100%' }} />
            </EtfCardWithTopPanel>
        );
    }

    let rowsData: Array<any>;

    switch (label) {
        case MarketTrend.KeyAssetClassesDetails: {
            rowsData = (result.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                asset_class: keyAssetClassesTickerDisplayName[row.composite_ticker],
            }));
            break;
        }
        case MarketTrend.SectorPerformanceDetails: {
            rowsData = (result.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                sector: SectorPerformanceTickerDisplayName[row.composite_ticker],
            }));
            break;
        }
        default:
            rowsData = result.data;
    }

    if (drillDownProps) {
        rowsData = rowsData.filter((row) => {
            if (drillDownProps.categoryOne) {
                return row[categoriesToResponceCategoryField[Categories.CategoryOne]] === drillDownProps.categoryOne;
            }
            if (drillDownProps.assetClass) {
                return row[categoriesToResponceCategoryField[Categories.AssetClass]] === drillDownProps.assetClass;
            }
            throw new Error("Can't find categoryOne or assetClass value for the drill down.");
        });

        breadcrumbsConfig = [
            {
                text: Categories.AssetClass,
                callback: () => {
                    setDrillDownProps(null);
                    setSelectedCategory(Categories.AssetClass);
                },
            },
            {
                text: (!drillDownProps.categoryOne ? `${Categories.AssetClass}: ` : '') + drillDownProps.assetClass,
                callback: () => {
                    setDrillDownProps({
                        categoryOne: '',
                        assetClass: drillDownProps.assetClass,
                    });
                    setSelectedCategory(getNextLevelCategory(Categories.AssetClass));
                },
            },
        ];

        if (drillDownProps.categoryOne) {
            breadcrumbsConfig.push({
                text: `${Categories.CategoryOne}: ${drillDownProps.categoryOne}`,
            });
        }
    }

    return (
        <>
            {rowsData.length > 0 ? (
                <AgGirdCard
                    label={label}
                    description={description}
                    columnDefs={columnDefs}
                    rowsData={rowsData}
                    dateRangesPanelConfig={dateRangesPanelConfig}
                    categoriesPanelConfig={categoriesPanelConfig}
                    breadcrumbsConfig={breadcrumbsConfig}
                />
            ) : (
                <EtfCardWithTopPanel>
                    <NoInformationAvailable sx={{ width: '100%' }} />
                </EtfCardWithTopPanel>
            )}
        </>
    );
}
