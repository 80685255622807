import http from '../../../microservices-common';
import { CognitoAuthResponse, isLoggedInResponse } from '../types';
// Normally this would be an api request
// return axios.post('/auth/login', data);

export const msaIsLogedIn = (): Promise<isLoggedInResponse> => {
    return http.get('SP/msa/user/isLoggedIn').then((response) => {
        return response.data;
    });
};

export const msaLoginCognito = (currentEnvironment: string): Promise<CognitoAuthResponse> => {
    return http
        .get('SP/msa/user/getCognitoToken', { params: { currentEnvironment: currentEnvironment } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return null;
        });
};

export const msaRefreshCognito = (
    refreshCognitoToken: string,
    currentEnvironment: string,
): Promise<CognitoAuthResponse> => {
    return http
        .get('SP/msa/user/refreshCognitoToken', {
            params: { refreshToken: refreshCognitoToken, currentEnvironment: currentEnvironment },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return null;
        });
};
