import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from 'components/layout/Grid';
import React from 'react';
import { fontFamilies } from 'utils';

type Props = {
    children: React.ReactNode[];
};

export const OverflowElipsis = styled(Paper)(({ theme }) => ({
    display: '-webkit-box',
    WebkitLineClamp: '2',
    wordWrap: 'break-word',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxShadow: 'none',
}));

export const Item = styled(OverflowElipsis)(({ theme }) => ({
    lineHeight: 1,
    fontSize: '15px',
    fontFamily: fontFamilies.GraphikRegular,
    color: '#002B5A',
    marginTop: '24px',
}));

export const Value = styled(Item)(({ theme }) => ({
    textAlign: 'right',
}));

export function ETFTwoColumnGrid({ children }: Props) {
    return (
        <Grid item container alignItems='baseline' columnSpacing={'28px'}>
            {children.map((element, index) => {
                return (
                    <Grid item xs={6} key={index}>
                        {element}
                    </Grid>
                );
            })}
        </Grid>
    );
}
