import { AgGridReact } from 'ag-grid-react';
import { getAgGridFormatter } from 'components/AgGrid';
import { ETFCard } from 'components/layout';
import { colDefType } from 'components/types/agGrid';
import { forwardRef } from 'react';
import {
    Categories,
    CategoryToLidDbFieldName,
    MarketTrendsDateRanges,
    marketTrendsDateRangesToDisplayString,
} from 'utils';
import { ValueTypes } from 'utils/valuesFormatter';
import { getEtfs } from '../../api/market';
import { NoInformationAvailable } from '../../components/shared/utils';
import { MarketTrend } from '../../types/market';
import { AgGirdCard } from './AgGridCard';
import { getCompanyDetailsLinkRenderer } from './renderers';

export type EtfsGridCardProps = {
    lid: number;
    categoryLevel: Categories;
    label: MarketTrend;
    dateRange: MarketTrendsDateRanges;
    maxGridContainerHeightPercentage?: number;
};

function getDataFieldPath(label: MarketTrend, dateRange: MarketTrendsDateRanges) {
    if (label === MarketTrend.FlowsToAssetsDetails) {
        switch (dateRange) {
            case MarketTrendsDateRanges.OneMonth:
                return 'data_returns_flow.flows_to_assets_ratio_one_month';
            case MarketTrendsDateRanges.ThreeMonth:
                return 'data_returns_flow.flows_to_assets_ratio_three_month';
            case MarketTrendsDateRanges.OneYear:
                return 'data_returns_flow.flows_to_assets_ratio_one_year';
            case MarketTrendsDateRanges.YTD:
                return 'data_returns_flow.flows_to_assets_ratio_ytd';
        }
    }
    if (label === MarketTrend.PerformanceDetails) {
        switch (dateRange) {
            case MarketTrendsDateRanges.OneMonth:
                return 'data_returns_flow.return_split_and_dividend_one_month';
            case MarketTrendsDateRanges.ThreeMonth:
                return 'data_returns_flow.return_split_and_dividend_three_month';
            case MarketTrendsDateRanges.OneYear:
                return 'data_returns_flow.return_split_and_dividend_one_year';
            case MarketTrendsDateRanges.YTD:
                return 'data_returns_flow.split_and_dividend_ytd_return';
        }
    }
    return '';
}

function getDataColumnHeaderName(label: MarketTrend, dateRange: MarketTrendsDateRanges) {
    if (label === MarketTrend.FlowsToAssetsDetails) {
        return `${marketTrendsDateRangesToDisplayString[dateRange]} Flows to Asset Ratio`;
    }
    if (label === MarketTrend.PerformanceDetails) {
        return `${marketTrendsDateRangesToDisplayString[dateRange]} Performance`;
    }
}

export const EtfsGridCard = forwardRef<AgGridReact<colDefType>, EtfsGridCardProps>((props, ref) => {
    const { lid, categoryLevel, label, dateRange, maxGridContainerHeightPercentage } = props;

    const categoryFields = ['composite_name', 'composite_ticker', 'data_returns_flow.as_of_date', 'sponsor', getDataFieldPath(label, dateRange)];

    const etfsData = getEtfs({
        fields: categoryFields,
        filterBy: [
            {
                fieldName: CategoryToLidDbFieldName[categoryLevel],
                value: String(lid),
            },
        ],
        sortDirection: 'desc',
        orderBy: getDataFieldPath(label, dateRange)
    });

    if (etfsData.isLoading) {
        return <ETFCard isLoading={etfsData.isLoading} />;
    }

    if (!(etfsData.data && etfsData.data.length > 0)) {
        return <NoInformationAvailable />;
    }

    const companyDetailsLinkRenderer = getCompanyDetailsLinkRenderer('cfraId');

    let columnDefs: any = [ //TODO: Fix this any: should be: let columnDefs: columnDefs = [
        {
            headerName: 'ETF Name',
            field: 'composite_name',
            cellRenderer: companyDetailsLinkRenderer,
            valueType: ValueTypes.Text
        },
        { headerName: 'Ticker', field: 'composite_ticker', cellRenderer: companyDetailsLinkRenderer },
        {
            headerName: 'Sponsor',
            field: 'sponsor',
            flex: 1,
            valueType: ValueTypes.Text
        },
        {
            headerName: getDataColumnHeaderName(label, dateRange),
            field: getDataFieldPath(label, dateRange),
            valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
            type: 'rightAligned',
            sort: 'desc',
            flex: 1,
            valueType: ValueTypes.Percentage
        },
    ];

    return (
        <AgGirdCard
            columnDefs={columnDefs}
            rowsData={etfsData.data}
            ref={ref}
            maxNumberOfRowsToDisplay={13}
            maxGridContainerHeightPercentage={maxGridContainerHeightPercentage}
        />
    );
});
