import { createRoot } from 'react-dom/client';
import { Categories, MarketTrendsDateRanges } from 'utils';

export function getExportFileName(
    fileNameBegining: string,
    selectedCategory: Categories,
    selectedDateRange: MarketTrendsDateRanges,
) {
    return [fileNameBegining, selectedCategory, selectedDateRange].join(' ').toLocaleLowerCase().replaceAll(' ', '-');
}

export function renderWithContainer( // use this function ONLY if can't render the component in a different way, for example, if rendering the component under main root tree is not possible
    containerName: string,
    getChildren: (unmountCallback: () => void) => React.ReactNode,
) {
    let container: HTMLDivElement;
    const overlayModalCOntainers = document.getElementsByClassName(containerName);
    if (overlayModalCOntainers.length > 0) {
        container = overlayModalCOntainers[0] as HTMLDivElement;
    } else {
        container = document.createElement('div');
        container.classList.add(containerName);
        document.body.appendChild(container);
    }

    const root = createRoot(container);
    root.render(getChildren(() => root.unmount()));
}

export type sortDirection = 'desc' | 'asc';
export type prefetchFunctionProps = {
    category: Categories;
    dateRange: MarketTrendsDateRanges;
    sortDirection: sortDirection;
};
export type prefetchDataProps = {
    selectedCategory: Categories;
    selectedDateRange: MarketTrendsDateRanges;
    sortDirections: Array<sortDirection>;
    prefetchFunction: ({ category, dateRange, sortDirection }: prefetchFunctionProps) => void;
};

export function prefetchData({
    selectedCategory,
    selectedDateRange,
    prefetchFunction,
    sortDirections = ['desc', 'asc'],
}: {
    selectedCategory: Categories;
    selectedDateRange: MarketTrendsDateRanges;
    prefetchFunction: ({ category, dateRange, sortDirection }: prefetchFunctionProps) => void;
    sortDirections?: Array<sortDirection>;
}) {
    Object.values(Categories).forEach((category) => {
        Object.values(MarketTrendsDateRanges).forEach((dateRange) => {
            sortDirections.forEach((sortDirection) => {
                if (category === selectedCategory && dateRange === selectedDateRange) {
                    return; // avoid unnecessary request, which already done
                }
                prefetchFunction({ category, dateRange, sortDirection });
            });
        });
    });
}
