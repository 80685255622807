import Box from '@mui/material/Box';
import React from 'react';

type Props = {
    children: React.ReactNode;
    style?: Object;
};

export function ETFTabContainer({ children, style }: Props) {
    return (
        <Box
            sx={{
                ...{
                    backgroundColor: '#F2F5FC',
                    padding: '32px 18px 72px 18px',
                },
                ...style,
            }}>
            {children}
        </Box>
    );
}
