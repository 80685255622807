import { SxProps } from '@mui/material';
import { ETFCircleButton } from 'components/layout';
import React, { useState } from 'react';
import { DateRanges } from 'utils';

export type ETFButtonsPannelButtonProps = {
    name: string | DateRanges;
    callback: (selectedPeriod: DateRanges | string) => void;
    disabled: boolean;
    isDefault?: boolean;
};

export function ETFButtonsPannel({
    buttonsConfig,
    buttonsStyles,
}: {
    buttonsConfig: Array<ETFButtonsPannelButtonProps>;
    buttonsStyles?: SxProps;
}): JSX.Element {
    const defaultPeriodSelected = buttonsConfig.find((element) => element.isDefault)?.name || buttonsConfig[0].name;
    const [clickedButton, setClickedButton] = useState(defaultPeriodSelected ? defaultPeriodSelected : '');

    const buttons: Array<React.ReactElement> = [];

    buttonsConfig.forEach((buttonConfig) => {
        const buttonCallback = (event: React.MouseEvent<HTMLButtonElement>) => {
            setClickedButton(event.currentTarget.name);
            buttonConfig.callback(event.currentTarget.name);
        };

        const isSelected = (): boolean => clickedButton === buttonConfig.name;

        buttons.push(
            <ETFCircleButton
                text={buttonConfig.name}
                disabled={buttonConfig.disabled}
                callback={buttonCallback}
                isSelected={isSelected()}
                buttonStyles={buttonsStyles}
            />,
        );
    });

    return <>{React.Children.toArray(buttons)}</>;
}
