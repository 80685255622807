import useMediaQuery from '@mui/material/useMediaQuery';
import { ColumnChart } from 'components/Chart';
import { defaultFontSize } from 'components/Chart/Options';
import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { useRef } from 'react';
import { extractTickerFromParenthesis } from 'utils';
import { formatPercentages } from 'utils/valuesFormatter';
import { getKeyAssetClassesData } from '../../api/market';
import { MarketTrend } from '../../types/market';
import { MarketTrendsChartsTopPanel } from './shared/MarketTrendsChartsTopPanel';

export const tickerExchanges = [
    { ticker: 'XLC', exchange: 'ARCX' },
    { ticker: 'XLY', exchange: 'ARCX' },
    { ticker: 'XLP', exchange: 'ARCX' },
    { ticker: 'XLE', exchange: 'ARCX' },
    { ticker: 'XLF', exchange: 'ARCX' },
    { ticker: 'XLV', exchange: 'ARCX' },
    { ticker: 'XLI', exchange: 'ARCX' },
    { ticker: 'XLB', exchange: 'ARCX' },
    { ticker: 'XLRE', exchange: 'ARCX' },
    { ticker: 'XLK', exchange: 'ARCX' },
    { ticker: 'XLU', exchange: 'ARCX' },
];

// create matching between ticker and label to display
export const tickerDisplayName: Record<string, string> = {
    XLC: 'Comm. Services (XLC)',
    XLY: 'Consumer Discr. (XLY)',
    XLP: 'Consumer Staples (XLP)',
    XLE: 'Energy (XLE)',
    XLF: 'Financial (XLF)',
    XLV: 'Health Care (XLV)',
    XLI: 'Industrial (XLI)',
    XLB: 'Materials (XLB)',
    XLRE: 'Real Estate (XLRE)',
    XLK: 'Technology (XLK)',
    XLU: 'Utilities (XLU)',
};

export default function SectorPerformance() {
    const isBelow600px = useMediaQuery('(max-width:600px)');
    const [chartRef, setChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject | null>>(useRef(null));
    // getting UseQueryResult object with data for KeyAssetClasses chart
    const keyAssetClassesDataQueryResult = getKeyAssetClassesData({
        tickerExchanges: tickerExchanges,
    });

    // show card loading if data still loading
    if (keyAssetClassesDataQueryResult.isLoading) {
        return <ETFCard isLoading={keyAssetClassesDataQueryResult.isLoading} />;
    }
    const cardTitle = 'Sector Performance';
    // Show Nothing if no data
    if (!(keyAssetClassesDataQueryResult.data && keyAssetClassesDataQueryResult.data.length > 0))
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;
    // cut off UseQueryResult attributes, extract only KeyAssetClasses data
    let keyAssetClassesData = keyAssetClassesDataQueryResult.data;

    // copy array before sorting
    // https://stackoverflow.com/questions/64957735/typeerror-cannot-assign-to-read-only-property-0-of-object-object-array-in
    const keyAssetClassesDataSorted = [...keyAssetClassesData];

    // sort the data the exact order as in the keyAssetClassesData template
    keyAssetClassesDataSorted.sort((a, b) => {
        const tickers = tickerExchanges.map((val) => val.ticker);
        return tickers.lastIndexOf(a.composite_ticker) - tickers.lastIndexOf(b.composite_ticker);
    });

    // create function for setting columns names in csv export
    const columnHeaderFormatter = function (item: Highcharts.DataLabelsFormatterCallbackFunction) {
        if (item instanceof Highcharts.Axis && item.isXAxis) return 'GICS sectors';
        return item.name;
    };

    // set categories to display in chart
    const categories: Array<string> = keyAssetClassesDataSorted.map((val) => {
        if (Object.keys(tickerDisplayName).includes(val.composite_ticker))
            return tickerDisplayName[val.composite_ticker];
        // if no label for this ticker, just show ticker
        return val.composite_ticker;
    });

    const series = [
        {
            name: '1 Month',
            data: keyAssetClassesDataSorted.map((val) => formatPercentages(val.performance.month_1_return)),
            color: '#80bddc',
        },
        {
            name: '3 Month',
            data: keyAssetClassesDataSorted.map((val) => formatPercentages(val.performance.month_3_return)),
            color: '#007bb8',
        },
    ];

    const xAxisLabelsFormatter = function (this: Highcharts.AxisLabelsFormatterContextObject) {
        if (isBelow600px) {
            return extractTickerFromParenthesis(String(this.value));
        } else {
            let index = (this.value as string).lastIndexOf('(');
            if (typeof index !== 'undefined') {
                return `${(this.value as string).substring(0, index)}<br>${(this.value as string).substring(index)}`;
            }
        }

        return this.value as string;
    };

    const heightOfVerticalLables = isBelow600px ? 35 : 118;
    const legendLineHeight = 18;
    const veritcalDisatanceBetweenYAxisLabelsAndxAxisLables = 16;
    const chartMarginBottom =
        heightOfVerticalLables +
        veritcalDisatanceBetweenYAxisLabelsAndxAxisLables +
        defaultFontSize / 2 +
        legendLineHeight +
        36;
    const barwidth = 12;

    const asOfDate = keyAssetClassesDataSorted
        .map((value) => value.performance.as_of_date)
        .sort()
        .reverse()[0];

    return (
        <ETFCard containerStyles={{ position: 'relative' }}>
            <MarketTrendsChartsTopPanel
                title={cardTitle}
                chartRef={chartRef}
                detailsPathName={MarketTrend.SectorPerformanceDetails}
            />
            <ColumnChart
                categories={categories}
                series={series}
                subTitle={'Trailing total returns for benchmark sector ETFs'}
                exportFileName={`sector-performance-chart`}
                columnHeaderFormatter={columnHeaderFormatter}
                xAxisLabelsFormatter={xAxisLabelsFormatter}
                setChartRef={setChartRef}
                useMinValue={true}
                enableExportMenusAndTitle={false}
                plotOptionsBarGroupPadding={0.22}
                plotOptionsBarPointWidth={barwidth}
                chartHeight={410} // set height the same as for Key Asset Classes Performance chart, it should look better
                chartMarginBottom={chartMarginBottom} // 36px distance between xAxis labels and legend
                xAxisLabelsXValue={isBelow600px ? -defaultFontSize / 2 : -barwidth} // move categories labels when show on or two company name rows, the logic for this present in xAxisLabelsFormatter
                xAxisOffset={
                    heightOfVerticalLables + veritcalDisatanceBetweenYAxisLabelsAndxAxisLables + defaultFontSize / 2
                } // distance between bottom of categories labels and bottom of first yAxis label
            />
            <DataAsOfDate date={asOfDate} />
        </ETFCard>
    );
}
