import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fontWeights } from 'utils';
import { fFunction } from './types';

type ButtonProps = {
    callback?: fFunction;
    text: string;
    disabled?: boolean;
};

const buttonContentSize = 18;

export const CustomButton = styled(Button)(({ theme }) => ({
    color: '#1976d2',
    '&:hover': {
        backgroundColor: '#1976d2',
        color: '#ffffff',
    },
    '&:disabled': {
        backgroundColor: 'red',
        color: '#ffffff',
    },
    fontWeight: fontWeights.ExtraBold,
    fontSize: `${buttonContentSize}px`,
    padding: '7px 7px 7px 7px',
    lineHeight: 1,
    minWidth: 0,
}));

export function ETFSquareButton(props: ButtonProps) {
    return (
        <CustomButton size='large' disabled={props.disabled} onClick={props.callback} name={props.text}>
            {props.text}
        </CustomButton>
    );
}
