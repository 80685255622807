import { MSA_ATHENA_URL } from './config/index';
import { MSA_MICROSERVICES_URL } from './config';
import Axios from 'axios';
import { storage } from 'utils';

const axios = Axios.create({
    baseURL: MSA_MICROSERVICES_URL,
    headers: {
        'Content-type': 'application/json',
    },
    withCredentials: true,
});

axios.interceptors.response.use(
    (response) => {
        if (response.data.reload === true) return axios(response.config);
        return response;
    },
    (error) => {
        if ([401, 403].includes(error.response.status)) {
            storage.clearToken();
            window.location.href = MSA_ATHENA_URL!;
        }
    },
);

export default axios;
