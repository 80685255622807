import moment, { Moment } from 'moment';

export const UnixTimestamp = (): number => {
    return Math.floor(Date.now() / 1000);
};

export const todayDateStringUS = (): string => {
    return moment().format('l'); // 9/27/2022
};

export const isWeekEnd = (date: Moment): boolean => {
    return date.isoWeekday() === 7 || date.isoWeekday() === 6; // return true is date is Sunday or Saturday
};

export const getNextMonday = (date: Moment): Moment => {
    let result = date.clone();
    while (result.isoWeekday() !== 1) result.add(1, 'days');
    return result;
};

export const getMomentObjectFrom = (date: string, format: string = 'YYYY-MM-DD'): Moment => {
    return moment.utc(date, format);
};
