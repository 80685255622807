import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Environments } from 'utils';
import App from './App';
import { ENABLE_MSW, ENVIRONMENT_NAME } from './config';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Start the mocking conditionally.
if (ENVIRONMENT_NAME === Environments.Local && ENABLE_MSW) {
    // The way this is set up should prevent this from being in production build
    const { worker } = require('./test/server/mocks/browser'); // eslint-disable-line global-require
    worker.start();
}

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// TODO: This comes with Create React App. Will research best way to handle analytics in a future
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
