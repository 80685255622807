import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ETFCard, ItemDescription, ItemHeader } from 'components/layout/ETFCard';
import React from 'react';

type DividerProps = {
    imageSource: string;
    header: string;
    description: string;
};

const DividerHeader = styled(ItemHeader)(({ theme }) => ({
    paddingBottom: '12px',
    lineHeight: '1.5',
}));

const DividerDescription = styled(ItemDescription)(({ theme }) => ({
    lineHeight: '1.5',
}));

export const ETFDivider = React.forwardRef<HTMLDivElement, DividerProps>((props, ref) => {
    const { imageSource, header, description, ...rest } = props;
    return (
        <ETFCard
            containerStyles={{ paddingTop: '18px', paddingBottom: '18px' }}
            ref={ref}
            {...rest}
            id={`${header} divider`}>
            {/* id needs for analytics to determine divider position, don't remove it */}
            <Grid container>
                <Box component='img' src={imageSource} sx={{ height: '75px', paddingRight: '28px' }} />

                <Grid item xs container sx={{ alignContent: 'start' }}>
                    <Grid item xs={12}>
                        <DividerHeader>{header}</DividerHeader>
                    </Grid>
                    <Grid item xs={12}>
                        <DividerDescription>{description}</DividerDescription>
                    </Grid>
                </Grid>
            </Grid>
        </ETFCard>
    );
});
