import { useLocation, useRoutes } from 'react-router-dom';
// Pattern with child routes
import { HomeRoutes } from 'features/home';
// Pattern with no child routes
import { Analytics } from 'analytics/analytics';
import ErrorBoundary from 'components/fallbacks/ErrorBoundary';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import { Footer } from 'features/Footer';
import { ETF } from 'features/etfData';
import { MarketTrendsRoutes } from 'features/marketTrends/routes/MarketTrendsRoutes';
import { TopNavigation } from 'features/topNavigation';
import { useEffect } from 'react';
import { isLoggedIn, useAuth } from '../lib/authentication';
import { ScrollingContextProvider } from 'utils/scrolling';

export default function AppRoutes() {
    const commonRoutes = [
        { path: '*', element: <HomeRoutes /> },
        { path: '/etf/ticker/:ticker/exchange/:exchange', element: <ETF /> },
        { path: '/etf/cfra-id/:cfraId', element: <ETF /> },
        { path: '/market-trends/*', element: <MarketTrendsRoutes /> },
    ];

    const element = useRoutes([...commonRoutes]);
    const location = useLocation();
    const { user } = useAuth();

    useEffect(() => {
        if (user) isLoggedIn();
    }, [location, user]);

    useEffect(() => {
        globalThis.analytics = new Analytics();
    }, []);

    useEffect(() => {
        cfraData.userData = {
            name: user?.username || 'unautorized',
            company: 'test userCompany',
            group: 'test userGroup',
            authType: 'test userAuthType',
        };
    }, [user?.username]);

    if (!user) return null;

    return (
        <ScrollingContextProvider>
            <TopNavigation />
            {/* Error Boundary Ensures We never lose the Top Navigation if an error is thrown */}
            <ErrorBoundary FallbackComponent={ErrorFallback}>{element}</ErrorBoundary>
            <Footer />
        </ScrollingContextProvider>
    );
}
