import { ETFInfoButton } from 'components/ETFButton/ETFInfoButton';
import ETFModal from 'components/ETFModal';
import { useCallback } from 'react';

type Props = {
    title: string;
    description: string | React.ReactNode;
    buttonFontSize?: number;
};

export function ETFInfo({ title, description, buttonFontSize }: Props) {
    const getOpenModalButton = useCallback(
        (handleOpen: () => void) => {
            return <ETFInfoButton onClick={handleOpen} buttonFontSize={buttonFontSize} />;
        },
        [buttonFontSize],
    );

    return <ETFModal getOpenComponent={getOpenModalButton} title={title} description={description} />;
}
