import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Categories,
    MarketTrendsDateRanges,
    ValueTypes,
    categoriesToAggregateField,
    categoriesToResponceCategoryField,
    dateRangeToFlowToAssetRatioDataPoint,
    formatPercentages,
} from 'utils';
import { getAggregationFlows } from '../../api/market';
import { MarketTrend } from '../../types/market';
import { ETFFlowsToAssetThemesAndFactorsData } from '../../types/research';
import { dataLimitForAssetCategory, getChangeEvents } from './shared/ThemeFilters';
import {
    ThemesAndFactorsChart,
    getColumnHeaderFormatter,
    onBarClick,
    xAxisColumnName,
} from './shared/ThemesAndFactors';
import { getExportFileName, prefetchData, prefetchFunctionProps, sortDirection } from './shared/utils';

const localStorageID = 'market-trends-etf-flows-themes-and-factors-';

export default function ETFFlowsToAssetThemesAndFactorsChart() {
    const navigate = useNavigate();
    const [highestChartRef, setHighestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();
    const [lowestChartRef, setLowestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();

    const [selectedCategory, setSelectedCategory] = useLocalStorage<Categories>(
        localStorageID + 'selected-category',
        Categories.CategoryOne,
    );

    const [selectedDateRange, setSelectedDateRange] = useLocalStorage<MarketTrendsDateRanges>(
        localStorageID + 'selected-date-range',
        MarketTrendsDateRanges.ThreeMonth,
    );

    const { handleCategoryChange, handleDateRangeChange } = getChangeEvents(setSelectedCategory, setSelectedDateRange);

    const dataMapFunction = function (item: string) {
        return getAggregationFlows({
            sortDirection: item as sortDirection,
            orderBy: dateRangeToFlowToAssetRatioDataPoint[selectedDateRange],
            aggregateBy: categoriesToAggregateField[selectedCategory],
            top: selectedCategory === Categories.AssetClass ? dataLimitForAssetCategory : 5,
        });
    };

    let chartData = ['desc', 'asc'].map(dataMapFunction);
    if (selectedCategory === Categories.AssetClass) chartData = [chartData[0]];

    prefetchData({
        selectedCategory,
        selectedDateRange,
        prefetchFunction: ({ category, dateRange, sortDirection }: prefetchFunctionProps) =>
            getAggregationFlows({
                sortDirection: sortDirection,
                orderBy: dateRangeToFlowToAssetRatioDataPoint[dateRange],
                aggregateBy: categoriesToAggregateField[category],
                top: category === Categories.AssetClass ? dataLimitForAssetCategory : 5,
            }),
    });

    const detailsPathName = MarketTrend.FlowsToAssetsDetails;
    const onBarClickCallback = useMemo(
        () =>
            onBarClick<ETFFlowsToAssetThemesAndFactorsData>({
                selectedCategory,
                selectedDateRange,
                chartData,
                detailsPathName,
                navigate,
            }),
        [selectedCategory, selectedDateRange, chartData, detailsPathName, navigate],
    );

    // show card loading if data still loading
    if (chartData.find((data) => data.isLoading)) {
        return <ETFCard isLoading={true} />;
    }

    const cardTitle = 'Themes & Factors: Flows to Assets';
    // Show Nothing if no data
    if (!chartData.every((item) => item.data && item.data.length > 0)) {
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;
    }

    const yAxisColumnName = 'Flows to Assets Ratio';

    const columnsToFormatting = new Map<string, ValueTypes>();
    columnsToFormatting.set(xAxisColumnName, ValueTypes.Text);
    columnsToFormatting.set(yAxisColumnName, ValueTypes.Numeral);

    const asOfDate =
        chartData
            .flatMap((item) => item.data)
            .map((item) => item?.as_of_date)
            .sort()
            .reverse()[0] || '';

    const getChartData = () => {
        return chartData.map((item, index) => {
            let chartData = item.data?.map((value) =>formatPercentages(value[dateRangeToFlowToAssetRatioDataPoint[selectedDateRange] as keyof typeof value] as number));

            return {
                chartHeader:
                    selectedCategory === Categories.AssetClass
                        ? 'Flows to Assets Ratio'
                        : (index === 0 ? 'Highest' : 'Lowest') + ' Ratio',
                categories:
                    item.data?.map(
                        (value) =>
                            `${
                                value[
                                    categoriesToResponceCategoryField[selectedCategory] as keyof typeof value
                                ] as string
                            }`,
                    ) || [],
                categoriesData: chartData || [],
                asOfDates: item.data?.map((value) => value.as_of_date) || [],
                chartRefObject: index === 0 ? highestChartRef : lowestChartRef,
                chartRefSetter: index === 0 ? setHighestChartRef : setLowestChartRef,
                columnsToFormatting: columnsToFormatting,
                reverseYaxis: index > 0 && chartData?.every(value => value && value < 0),
            };
        });
    };

    return (
        <ETFCard containerStyles={{ paddingBottom: '16px', position: 'relative' }}>
            <ThemesAndFactorsChart
                title={cardTitle}
                subHeader={'View the highest and lowest ETF categories by Flows to Assets ratio'}
                tooltipFormatterValuePrefix={`${yAxisColumnName}:`}
                columnHeaderFormatter={getColumnHeaderFormatter(yAxisColumnName)}
                exportFileName={getExportFileName(
                    'flows-to-asset-themes-and-factors',
                    selectedCategory,
                    selectedDateRange,
                )}
                exportHeaders={[selectedCategory, selectedDateRange + ' Flows to Asset Ratio']}
                detailsPathName={detailsPathName}
                subChartsProps={getChartData()}
                dateRangesDropdownConfig={{
                    selectedDateRange: selectedDateRange,
                    handleChange: handleDateRangeChange,
                }}
                categoriesPanelConfig={{
                    selectedCategory: selectedCategory,
                    handleChange: handleCategoryChange,
                }}
                onBarClick={onBarClickCallback}
            />
            <DataAsOfDate date={asOfDate} />
        </ETFCard>
    );
}
