import { ETFDownloadButton } from 'components/ETFButton';
import { ETFMenu, MenuItemProps } from 'components/layout/ETFMenu';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { exportChart, exportMultipleCharts, MultipleExport } from './Export';

type ExportMenusProps = {
    chartRef: React.RefObject<HighchartsReact.RefObject | null>;
};

const getItemName = (format: string) => {
    switch (format.toLocaleLowerCase()) {
        case 'csv':
        case 'xlsx':
            return 'Download Data';
        default:
            return `Download ${format}`;
    }
};

const ExportMenusBase = (menuItemsProps: Map<string, () => void>,isCustom?:boolean) => {
    const menuItems: Array<MenuItemProps> = [];
    menuItemsProps.forEach((callback, menuItemName) => {
        menuItems.push({
            itemName: !isCustom ? getItemName(menuItemName) : menuItemName,
            callback: callback,
            });
        
    });
    return (
        <ETFMenu menuItems={menuItems}>
            <ETFDownloadButton />
        </ETFMenu>
    );
};

export default function ExportMenus(exportMenusProps: ExportMenusProps) {
    const menuItemsProps = new Map<string, () => void>();
    ['CSV', 'JPEG'].forEach((type) =>
        menuItemsProps.set(type, () =>
            exportChart({ chartRef: exportMenusProps.chartRef, type: type.toLocaleLowerCase() }),
        ),
    );
    return ExportMenusBase(menuItemsProps);
}

export function ExportMenusForMultipleCharts({ multipleExport }: { multipleExport: MultipleExport }) {
    const menuItemsProps = new Map<string, () => void>();
    ['XLSX', 'JPEG'].forEach((type) =>
        menuItemsProps.set(type, () =>
            exportMultipleCharts({ multipleExport: multipleExport, type: type.toLocaleLowerCase() }),
        ),
    );
    return ExportMenusBase(menuItemsProps);
}

export function TableExportMenus({ exportCallback,isCustom,customFields=new Map<string,()=>void>() }: { exportCallback: () => void,isCustom?:boolean,customFields?:any }) {
    const menuItemsProps = new Map<string, () => void>([...customFields]);
    if(!isCustom){
        menuItemsProps.set('CSV', exportCallback);
    }
    return ExportMenusBase(menuItemsProps,isCustom);
}
