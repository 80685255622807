import Box from '@mui/material/Box';
import { Page } from 'analytics/Page';
import Skeleton from 'components/layout/Skeleton';
import { useParams } from 'react-router-dom';
import { getEtfDataOverview } from '../api/etfDetailsData';
import ETFDetailTabs from '../components/ETFDetailsTabs';
import ETFDetailsData from '../components/EtfDetailsData';
import { ETFDetailsParams } from '../types/research';

export function ETF() {
    const { ticker, exchange, cfraId } = useParams<keyof ETFDetailsParams>() as ETFDetailsParams;

    // Why we send parameters as dictionary?
    const latestUpdatesQuery = getEtfDataOverview({ ticker: ticker, exchange: exchange, cfraId: cfraId });

    // show loading if data still loading
    if (latestUpdatesQuery.isLoading) {
        return (
            <Box sx={{ minHeight: 1000 }}>
                <Skeleton />
            </Box>
        );
    }

    // Show Nothing if no data
    if (!latestUpdatesQuery || !latestUpdatesQuery.data) return null;

    const companyData = {
        ticker: latestUpdatesQuery.data.ticker,
        exchange: latestUpdatesQuery.data.exchange_code,
        cfraId: latestUpdatesQuery.data.cfra_id,
    };

    function getSection() {
        const notRecognized = 'not recognized';
        const flowsAndPerformanceDividerOffsetTop = document.getElementById('Flows and Performance divider');
        if (!flowsAndPerformanceDividerOffsetTop) return notRecognized;
        interface HTMLButtonElementWithRole extends HTMLButtonElement {
            role: string;
        }
        const flowsAndPerformanceTab = Array.from(document.querySelectorAll('button')).find(
            (el) => el.textContent === 'Flows & Performance' && (el as HTMLButtonElementWithRole).role === 'tab',
        );
        if (!flowsAndPerformanceTab) return notRecognized;
        const flowsAndPerformanceTabTopPosition =
            flowsAndPerformanceTab.getBoundingClientRect().top + flowsAndPerformanceTab.offsetHeight;
        globalThis.cfraData.pageData.section =
            window.scrollY > flowsAndPerformanceDividerOffsetTop.offsetTop - flowsAndPerformanceTabTopPosition
                ? 'Flows & Performance'
                : 'Holdings & Exposure';
    }
    globalThis.analytics?.waitUntil?.(() => {
        const flowsAndPerformanceDivider = document.getElementById('Flows and Performance divider');
        const skeletons = document.getElementsByClassName('analytics-cfra-skeleton');
        if (!flowsAndPerformanceDivider) return false;
        return skeletons.length === 0 || (skeletons[0] as HTMLElement).offsetTop > flowsAndPerformanceDivider.offsetTop;
    }, [getSection]);

    return (
        <Page
            name='etf details'
            section={getSection()}
            ticker={companyData.ticker}
            etfName={latestUpdatesQuery.data.composite_name}
            etfAssetClass={latestUpdatesQuery.data.asset_class}
            exchange={companyData.exchange}>
            <ETFDetailsData etfDetailsData={latestUpdatesQuery.data} />
            <ETFDetailTabs latestUpdatesQuery={latestUpdatesQuery.data} companyData={companyData} />
        </Page>
    );
}
export default ETF;
