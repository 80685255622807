import { ReactComponent as InfoIcon } from 'assets/images/InfoIcon.svg';
import { ETFIconButton } from './ETFIconButton';
import { ButtonProps } from './types';

export function ETFInfoButton({ buttonFontSize = 24, ...props }: ButtonProps) {
    return (
        <ETFIconButton
            Icon={InfoIcon}
            viewBoxSize={48}
            buttonFontSize={buttonFontSize}
            borderRadius={50}
            buttonStyles={{ padding: '0px' }}
            {...props}
        />
    );
}
