import { ETFSearchByParams, getRequestQuery, UseData } from 'utils';
import {
    EtfData,
    ETFFlowsToAssetThemesAndFactorsData,
    ETFPerformanceThemesAndFactorsData,
    KeyAssetClassesData,
} from '../types/research';

export function getKeyAssetClassesData({ cfraIds, tickerExchanges }: ETFSearchByParams) {
    return UseData<Array<KeyAssetClassesData>>({
        requestQuerry: getRequestQuery({ cfraIds, tickerExchanges }, 'performance/'),
        queryKey: ['getKeyAssetClassesData', `${JSON.stringify(cfraIds)}`, `${JSON.stringify(tickerExchanges)}`],
    });
}

export function getAggregationFlows({ top, orderBy, sortDirection, aggregateBy }: ETFSearchByParams) {
    return UseData<Array<ETFFlowsToAssetThemesAndFactorsData>>({
        requestQuerry: getRequestQuery({ top, orderBy, sortDirection, aggregateBy }, 'aggregation/flows/'),
        queryKey: ['getAggregationFlows', top, orderBy, sortDirection, aggregateBy],
    });
}

export function getAggregationPerformance({ top, orderBy, sortDirection, aggregateBy }: ETFSearchByParams) {
    const STALE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
    return UseData<Array<ETFPerformanceThemesAndFactorsData>>({
        requestQuerry: getRequestQuery({ top, orderBy, sortDirection, aggregateBy }, 'aggregation/performance/'),
        queryKey: ['getAggregationPerformance', top, sortDirection, orderBy, aggregateBy],
        config: { staleTime: STALE_TIME },
    });
}

export function getEtfs({ fields, filterBy, orderBy, sortDirection }: ETFSearchByParams) {
    return UseData<Array<EtfData>>({
        requestQuerry: getRequestQuery({ fields, filterBy, orderBy, sortDirection }, 'etfs/'),
        queryKey: ['getEtfs', fields, filterBy, orderBy, sortDirection],
    });
}
