import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { colDefType } from 'components/types/agGrid';
import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    useSyncExternalStore,
} from 'react';
import { ValueTypes, formatValue } from 'utils/valuesFormatter';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getSnapshot, subscribe } from 'components/hooks/windowDimensions';
import './agGrid.scss';

export type columnDefs = Array<ColDef<colDefType> | ColGroupDef<colDefType>> | null;

export function getAgGridFormatter(formattingType: ValueTypes) {
    return function (params: ValueFormatterParams) {
        return String(
            formatValue({
                value: params.value,
                formattingType: formattingType,
            }),
        );
    };
}

type AgGridProps = {
    columnDefs: columnDefs;
    rowsData: Array<any>;
    getResizebleMinWidthForColumn: (key: string) => number;
    updateCustomColumnsWidths?: (gridRef: React.RefObject<AgGridReact<colDefType>>) => void;
    maxNumberOfRowsToDisplay?: number;
    maxGridContainerHeightPercentage?: number; // the value in percentage from window height [1;100], needed for cases when grid container is centered, for example if grid is placed inside modal window
};

export const AgGrid = forwardRef<AgGridReact<colDefType>, AgGridProps>((props, ref) => {
    const { height } = useSyncExternalStore(subscribe, getSnapshot);
    const [gridTopPosition, setGridTopPosition] = useState<number>();
    const gridContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const gridContainerRefCurrent = gridContainerRef.current;
        if (gridContainerRefCurrent) {
            const { top } = gridContainerRefCurrent.getBoundingClientRect();
            setGridTopPosition(top);
        }
    }, [gridContainerRef]);

    const {
        columnDefs,
        rowsData,
        getResizebleMinWidthForColumn,
        updateCustomColumnsWidths,
        maxNumberOfRowsToDisplay = 20,
        maxGridContainerHeightPercentage,
    } = props;

    const gridRef = useRef<AgGridReact<colDefType>>(null);
    useImperativeHandle(ref, () => gridRef.current!);

    const defaultMaxWidth = 350;
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(
        (): ColDef => ({
            sortable: true,
            filter: true,
            resizable: true,
            autoHeight: true,
            maxWidth: defaultMaxWidth,
        }),
        [],
    );

    const defaultRowHeight = 46;

    function setMaxResizeWidthForAllColumnsTo(maxWidth: number) {
        const columnDefs = gridRef.current?.api.getColumnDefs();
        if (!columnDefs) return;
        gridRef.current?.api.setColumnDefs(columnDefs.map((columnDef) => ({ ...columnDef, maxWidth: maxWidth })));
    }

    const setMinResizeWidthForAllColumns = useCallback(() => {
        const columnDefs = gridRef.current?.api.getColumnDefs() as Array<colDefType>;
        if (!columnDefs) return;
        gridRef.current?.api.setColumnDefs(
            columnDefs.map((columnDef) => ({
                ...columnDef,
                minWidth: getResizebleMinWidthForColumn(columnDef.headerName),
            })),
        );
    }, [getResizebleMinWidthForColumn]);

    const setInitiallColumnDefsForAllColumns = useCallback(
        (keys: Array<string>) => {
            const localColumnDefs = gridRef.current?.api.getColumnDefs();
            if (!localColumnDefs) return;
            gridRef.current?.api.setColumnDefs(
                (localColumnDefs as Array<colDefType>).map((localColumnDef) => {
                    keys.forEach((key) => {
                        const colDefProp = (columnDefs as Array<colDefType>).filter(
                            (item) => item.field === localColumnDef.field,
                        )[0];

                        localColumnDef = {
                            ...localColumnDef,
                            [key]: colDefProp.hasOwnProperty(key)
                                ? colDefProp[key as keyof typeof colDefProp]
                                : defaultColDef[key as keyof typeof defaultColDef],
                        };
                    });
                    return localColumnDef;
                }),
            );
        },
        [columnDefs, defaultColDef],
    );

    function calculateAllColumnsWidths() {
        return (
            gridRef.current?.columnApi
                .getColumns()
                ?.reduce((prevResult, current) => (prevResult += current.getActualWidth()), 0) || 0
        );
    }

    const autoSizeAllColumns = useCallback(() => {
        if (!gridRef.current) return;
        const keys = ['maxWidth', 'minWidth'];
        setInitiallColumnDefsForAllColumns(keys);
        gridRef.current?.columnApi.autoSizeAllColumns(false);
        const right = gridRef.current.api.getHorizontalPixelRange().right;
        const actualWidthExceededViewableWidth = calculateAllColumnsWidths() > right;
        if (actualWidthExceededViewableWidth) {
            gridRef.current?.columnApi.autoSizeAllColumns(true);
        }

        if (updateCustomColumnsWidths) {
            updateCustomColumnsWidths(gridRef);
        }

        setMaxResizeWidthForAllColumnsTo(right);
        setMinResizeWidthForAllColumns();
    }, [setInitiallColumnDefsForAllColumns, setMinResizeWidthForAllColumns, updateCustomColumnsWidths]);

    function calculateHeight() {
        const windowHeight = height || 0;
        const numberOfRowsToShow =
            rowsData.length > maxNumberOfRowsToDisplay ? maxNumberOfRowsToDisplay : rowsData.length; // show vertical scroll if more than maxNumberOfRowsToDisplay rows
        const staticGridHeight = (numberOfRowsToShow + 1.5) * defaultRowHeight; // 1 for header row, 0.1 for preventing unnecessary vertical scroll
        const _gridTopPosition = gridTopPosition || 0;

        if (maxGridContainerHeightPercentage) {
            const maxGridContainerHeight = (windowHeight * maxGridContainerHeightPercentage) / 100;
            const gridContainerSpaceAboveTheGrid = _gridTopPosition - (windowHeight - maxGridContainerHeight) / 2;
            const maxGridHeight = maxGridContainerHeight - gridContainerSpaceAboveTheGrid;

            return staticGridHeight < maxGridHeight ? staticGridHeight : maxGridHeight;
        }

        return staticGridHeight < windowHeight - _gridTopPosition ? staticGridHeight : windowHeight - _gridTopPosition;
    }

    return (
        <div ref={gridContainerRef} style={{ width: '100%' }} className='cfra-ag-grid'>
            <div
                className='ag-theme-alpine'
                style={{
                    height: calculateHeight(),
                }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowsData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true}
                    rowSelection='single'
                    getRowHeight={() => defaultRowHeight}
                    headerHeight={defaultRowHeight}
                    suppressPropertyNamesCheck={true} // This is not ideal, but see: https://github.com/ag-grid/ag-grid/issues/2320
                    onGridReady={() => autoSizeAllColumns()}
                    onGridSizeChanged={() => autoSizeAllColumns()}
                />
            </div>
        </div>
    );
});
