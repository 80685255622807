import { initReactQueryAuth } from '../lib/react-query-auth';

// TODO: Wrap this component
import CircularProgress from '@mui/material/CircularProgress';
import { COGNITO_ENVIRONMENT, MSA_ATHENA_URL } from '../config';
import {
    AuthUser,
    LoginCredentialsDTO,
    msaIsLogedIn,
    msaLoginCognito,
    msaRefreshCognito,
    RegisterCredentialsDTO,
} from '../features/auth';
import { CognitoAuthResponse, isLoggedInResponse } from '../features/auth/types';
import { storage } from '../utils/storage';
import { UnixTimestamp } from '../utils/time';

// Sets Token in Storage
async function handleUserResponse(userData: CognitoAuthResponse) {
    if (userData) storage.setToken(userData);
    return storage.getToken();
}

// Loads User Profile from Token
async function loadUser() {
    return await syncAuth();
}

export async function isLoggedIn() {
    var msaResponse: isLoggedInResponse = await msaIsLogedIn();
    if (msaResponse.isLoggedIn) return true;

    storage.clearToken();
    window.location.href = MSA_ATHENA_URL!;
    return false;
}

export async function syncAuth() {
    var user: AuthUser = storage.getToken();
    var response: CognitoAuthResponse;

    if (!user || UnixTimestamp() > user.refreshTokenTimeExp) {
        response = await msaLoginCognito(COGNITO_ENVIRONMENT);
        user = await handleUserResponse(response);
    }

    if (user && UnixTimestamp() > user.exp) {
        response = await msaRefreshCognito(user.refreshToken, COGNITO_ENVIRONMENT);
        user = await handleUserResponse(response);
    }

    return user;
}

// Logs In User
export async function loginFn() {
    var user: AuthUser | null = null;
    return user;
}

// Registers User
async function registerFn(data: RegisterCredentialsDTO) {
    var user: AuthUser | null = null;
    return user;
}

// Logs User out
async function logoutFn() {
    storage.clearToken();

    // refresh
    window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
    loadUser,
    loginFn,
    logoutFn,
    registerFn,
    LoaderComponent() {
        // Component to load when user is not logged in (Currently disabled)
        return (
            <div>
                <CircularProgress />
                <span>loading</span>
            </div>
        );
    },
};

// Init React Query Auth
const initReactQueryAuthorization = initReactQueryAuth<
    AuthUser | null,
    unknown,
    LoginCredentialsDTO,
    RegisterCredentialsDTO
>(authConfig);

// Create useAuth Hook that exposes user information
export const { AuthProvider, useAuth } = initReactQueryAuthorization;
