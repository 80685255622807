import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { SxProps, styled } from '@mui/material/styles';
import { TableExportMenus } from 'components/Chart/ExportMenus';
import { ETFCloseButton } from 'components/layout';
import Grid from 'components/layout/Grid';
import React, { useMemo, useState } from 'react';
import { fontFamilies } from 'utils';

const ModalBox = styled(Grid, {
    name: 'MuiModalBox',
    slot: 'Root',
    // We are specifying here how the styleOverrides are being applied based on props
    overridesResolver: (props, styles) => [styles.root],
})(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none', // fix black border around modal on press shift
    overflowY: 'auto',
    maxHeight: '90%',
    borderBottom: 1,
    borderColor: '#EAEAEA',
    backgroundColor: '#fff',
    maxWidth: '1330px',
    padding: '36px',
    width: '95%',
    borderRadius: '5px',
    zIndex: 1900,
}));

export const ItemHeader = styled(Paper)(({ theme }) => ({
    fontSize: '38px',
    color: '#002B5A',
    lineHeight: '1',
    paddingBottom: '36px',
    fontFamily: fontFamilies.GraphikMedium,
}));

export const ItemDescription = styled(Paper)(({ theme }) => ({
    fontSize: '15px',
    color: '#57626a',
    fontFamily: fontFamilies.GraphikRegular,
    lineHeight: '26px',
}));

type Props = {
    title?: string;
    titleStyle?: SxProps;
    description?: string | React.ReactNode;
    descriptionStyle?: SxProps;
    titleAndDescriptionContainerStyle?: SxProps;
    closeButtonFontSize?: number;
    children?: React.ReactNode;
    getOpenComponent?: (handleOpen: () => void) => React.ReactElement;
    exportCallback?: () => void;
    callbackOnClose?: () => void;
};

export default function ETFModal({
    title,
    titleStyle,
    description,
    descriptionStyle,
    titleAndDescriptionContainerStyle,
    closeButtonFontSize,
    children,
    getOpenComponent,
    exportCallback,
    callbackOnClose,
}: Props) {
    const [openModal, setOpenModal] = useState(getOpenComponent ? false : true);
    const handleClose = () => {
        setOpenModal(false);
        if (callbackOnClose) {
            callbackOnClose();
        }
    };

    const OpenComponent = useMemo(() => getOpenComponent?.(() => setOpenModal(true)), [getOpenComponent]);

    return (
        <>
            {OpenComponent}
            {openModal ? (
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                    style={{ zIndex: 1600 }}
                    BackdropProps={{ style: { backgroundColor: '#002B5A', opacity: 0.33 } }}>
                    {/* Need cfra-etf-modal class name to apply transform-style: preserve-3d to all elements inside, this fix issue modal content is blurry */}
                    <ModalBox container alignItems='baseline'>
                        <Grid item xs={12} sx={titleAndDescriptionContainerStyle}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                {title ? <ItemHeader sx={titleStyle}>{title}</ItemHeader> : null}
                                <Grid item sx={{ display: 'flex' }}>
                                    {exportCallback ? <TableExportMenus exportCallback={exportCallback} /> : null}
                                    <ETFCloseButton
                                        onClick={handleClose}
                                        buttonFontSize={closeButtonFontSize}
                                        sx={{ paddingLeft: '16px' }}
                                    />
                                </Grid>
                            </Grid>
                            {description ? (
                                <Grid item xs={12}>
                                    <ItemDescription sx={descriptionStyle}>{description}</ItemDescription>
                                </Grid>
                            ) : null}
                        </Grid>
                        {children}
                    </ModalBox>
                </Modal>
            ) : null}
        </>
    );
}
