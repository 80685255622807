import { rest } from 'msw';
import { Frequencies, getMomentObjectFrom } from 'utils';
import { API_URL, MSA_MICROSERVICES_URL } from './../../../config';
import aggregationFlowsAssetClassOneMonth from './json/aggregation/flows/asset_class/top5/oneMonth.json';
import aggregationFlowsAssetClassOneYear from './json/aggregation/flows/asset_class/top5/oneYear.json';
import aggregationFlowsAssetClassThreeMonth from './json/aggregation/flows/asset_class/top5/threeMonth.json';
import aggregationFlowsAssetClassYtd from './json/aggregation/flows/asset_class/top5/ytd.json';
import aggregationFlowsCfraLevelOneOneMonth from './json/aggregation/flows/cfra_level_1/top5/oneMonth.json';
import aggregationFlowsCfraLevelOneOneYear from './json/aggregation/flows/cfra_level_1/top5/oneYear.json';
import aggregationFlowsCfraLevelOneThreeMonth from './json/aggregation/flows/cfra_level_1/top5/threeMonth.json';
import aggregationFlowsCfraLevelOneYtd from './json/aggregation/flows/cfra_level_1/top5/ytd.json';
import aggregationFlowsCfraLevelTwoOneMonth from './json/aggregation/flows/cfra_level_2/top5/oneMonth.json';
import aggregationFlowsCfraLevelTwoOneYear from './json/aggregation/flows/cfra_level_2/top5/oneYear.json';
import aggregationFlowsCfraLevelTwoThreeMonth from './json/aggregation/flows/cfra_level_2/top5/threeMonth.json';
import aggregationFlowsCfraLevelTwoYtd from './json/aggregation/flows/cfra_level_2/top5/ytd.json';
import aggregationPerformanceAssetClassOneMonth from './json/aggregation/performance/asset_class/top5/oneMonth.json';
import aggregationPerformanceAssetClassOneYear from './json/aggregation/performance/asset_class/top5/oneYear.json';
import aggregationPerformanceAssetClassThreeMonth from './json/aggregation/performance/asset_class/top5/threeMonth.json';
import aggregationPerformanceAssetClassYtd from './json/aggregation/performance/asset_class/top5/ytd.json';
import aggregationPerformanceCfraLevelOneOneMonth from './json/aggregation/performance/cfra_level_1/top5/oneMonth.json';
import aggregationPerformanceCfraLevelOneOneYear from './json/aggregation/performance/cfra_level_1/top5/oneYear.json';
import aggregationPerformanceCfraLevelOneThreeMonth from './json/aggregation/performance/cfra_level_1/top5/threeMonth.json';
import aggregationPerformanceCfraLevelOneYtd from './json/aggregation/performance/cfra_level_1/top5/ytd.json';
import aggregationPerformanceCfraLevelTwoOneMonth from './json/aggregation/performance/cfra_level_2/top5/oneMonth.json';
import aggregationPerformanceCfraLevelTwoOneYear from './json/aggregation/performance/cfra_level_2/top5/oneYear.json';
import aggregationPerformanceCfraLevelTwoThreeMonth from './json/aggregation/performance/cfra_level_2/top5/threeMonth.json';
import aggregationPerformanceCfraLevelTwoYtd from './json/aggregation/performance/cfra_level_2/top5/ytd.json';
import getCognitoTokenDefault from './json/authorization/getCognitoTokenDefault.json';
import isLoggedInDefault from './json/authorization/isLoggedInDefault.json';
import bestPerformingFlowsDefault from './json/categoryLevel2/bestPerformingFlowsDefault.json';
import bestPerformingPerformanceDefault from './json/categoryLevel2/bestPerformingPerformanceDefault.json';
import worsePerformingFlowsDefault from './json/categoryLevel2/worsePerformingFlowsDefault.json';
import worsePerformingPerformanceDefault from './json/categoryLevel2/worsePerformingPerformanceDefault.json';
import creditExposureDefault from './json/creditExposureDefault.json';
import creditExposureHistoryDefault from './json/creditExposureHistoryDefault.json';
import etfDataBonds from './json/etfDataBonds.json';
import etfDataStocks from './json/etfDataStocks.json';
import etfs from './json/etfs.json';
import flowsFiveYearsDefault from './json/flows/flowsFiveYearsDefault.json';
import flowsOneMonthDefault from './json/flows/flowsOneMonthDefault.json';
import flowsOneWeekDefault from './json/flows/flowsOneWeekDefault.json';
import flowsOneYearDefault from './json/flows/flowsOneYearDefault.json';
import flowsSixMonthDefault from './json/flows/flowsSixMonthDefault.json';
import flowsThreeMonthDefault from './json/flows/flowsThreeMonthDefault.json';
import flowsThreeYearsDefault from './json/flows/flowsThreeYearsDefault.json';
import flowsAndPerformanceDefault from './json/flowsAndPerformanceDefault.json';
import holdingsAndExposureDefault from './json/holdingsAndExposureDefault.json';
import keyAssetClassesPerformanceDefault from './json/keyAssetClassesPerformanceDefault.json';
import maturityExposureDefault from './json/maturityExposureDefault.json';
import mostViewedArticlesUSDefault from './json/mostViewedArticlesDefault.json';
import recentlyUpdatedReportsDefault from './json/recentlyUpdatedReportsDefault.json';
import sectorExposureDefault from './json/sectorExposureDefault.json';
import sectorExposureHistoryDefault from './json/sectorExposureHistoryDefault.json';
import sectorPerformanceDefault from './json/sectorPerformanceDefault.json';

export const handlersForTests = [
    // handlers for Authorizaation requests
    // Create Handler for isLoggedIn request, for cases when need to work only with msw, if msa authorization don't work use handlersForTests.concat(authorizationHandler);
    rest.get(`${MSA_MICROSERVICES_URL}/SP/msa/user/isLoggedIn`, (req, res, ctx) => res(ctx.json(isLoggedInDefault))),

    // Create Handler for isLoggedIn request, for cases when need to work only with msw, if msa authorization don't work use handlersForTests.concat(authorizationHandler);
    rest.get(`${MSA_MICROSERVICES_URL}/SP/msa/user/refreshCognitoToken`, (req, res, ctx) =>
        res(ctx.json(getCognitoTokenDefault)),
    ),

    // Create Handler for isLoggedIn request, for cases when need to work only with msw, if msa authorization don't work use handlersForTests.concat(authorizationHandler);
    rest.get(`${MSA_MICROSERVICES_URL}/SP/msa/user/getCognitoToken`, (req, res, ctx) =>
        res(ctx.json(getCognitoTokenDefault)),
    ),

    // Create Handler for Recently Updated Reports
    rest.get(`${MSA_MICROSERVICES_URL}/SP/msa/recentlyUpdatedReport`, (req, res, ctx) =>
        res(ctx.json(recentlyUpdatedReportsDefault)),
    ),

    // Create Handler for Most Viewed Articles
    rest.get(`${MSA_MICROSERVICES_URL}/SP/msa/mostViewedArticles`, (req, res, ctx) =>
        res(ctx.json(mostViewedArticlesUSDefault)),
    ),

    // Create Handler for ETF Data page > Overwiev tab
    rest.get(`${API_URL}/etf-insights/etf-data/ticker/PTIN/exchange/ARCX`, (req, res, ctx) =>
        res(ctx.json(etfDataStocks)),
    ),
    rest.get(`${API_URL}/etf-insights/etf-data/cfra_id/00c7b9fc03884f3b931e1b358837db24`, (req, res, ctx) =>
        res(ctx.json(etfDataStocks)),
    ),

    // Create Handler for ETF Data page > Overwiev tab
    rest.get(`${API_URL}/etf-insights/etf-data/ticker/IGLB/exchange/ARCX`, (req, res, ctx) =>
        res(ctx.json(etfDataBonds)),
    ),
    rest.get(`${API_URL}/etf-insights/etf-data/cfra_id/03d037224c8b477d92e778a861b598f6`, (req, res, ctx) =>
        res(ctx.json(etfDataBonds)),
    ),

    // Create Handler for ETF Data page > Holdings & Exposure tab > Top Holdings card
    rest.get(`${API_URL}/etf-insights/etf-data/top-holdings/ticker/*/exchange/*`, (req, res, ctx) =>
        res(ctx.json(holdingsAndExposureDefault)),
    ),
    rest.get(`${API_URL}/etf-insights/etf-data/top-holdings/cfra_id/*`, (req, res, ctx) =>
        res(ctx.json(holdingsAndExposureDefault)),
    ),

    // Create Handler for ETF Data page > Holdings & Exposure tab > Sector Exposure card
    rest.get(`${API_URL}/etf-insights/etf-data/sector-exposure/cfra_id/*`, (req, res, ctx) =>
        res(ctx.json(sectorExposureDefault)),
    ),

    // Create Handler for ETF Data page > Holdings & Exposure tab > Maturity Exposure card
    rest.get(`${API_URL}/etf-insights/etf-data/maturity-exposure/cfra_id/*`, (req, res, ctx) =>
        res(ctx.json(maturityExposureDefault)),
    ),

    // Create Handler for ETF Data page > Holdings & Exposure tab > Credit Exposure card
    rest.get(`${API_URL}/etf-insights/etf-data/credit-exposure/cfra_id/*`, (req, res, ctx) =>
        res(ctx.json(creditExposureDefault)),
    ),

    // Create Handler for ETF Data page > Holdings & Exposure tab > Credit Exposure History card
    rest.get(`${API_URL}/etf-insights/etf-data/credit-exposure-history/ticker/*/exchange/*`, (req, res, ctx) =>
        res(ctx.json(creditExposureHistoryDefault)),
    ),

    // Create Handler for ETF Data page > Flows & Performance tab > ETF Performance card
    rest.get(`${API_URL}/etf-insights/etf-data/performance/ticker/*/exchange/*`, (req, res, ctx) =>
        res(ctx.json(flowsAndPerformanceDefault)),
    ),
    rest.get(`${API_URL}/etf-insights/etf-data/performance/cfra_id/*`, (req, res, ctx) =>
        res(ctx.json(flowsAndPerformanceDefault)),
    ),

    // Create Handler for ETF Data page > Holdings & Exposure tab > Sector Exposure History card
    rest.get(`${API_URL}/etf-insights/etf-data/sector-exposure-history/ticker/*/exchange/*`, (req, res, ctx) =>
        res(ctx.json(sectorExposureHistoryDefault)),
    ),

    rest.get(`${API_URL}/etf-insights/etf-data/performance/cfra_ids`, (req, res, ctx) =>
        res(ctx.json(keyAssetClassesPerformanceDefault)),
    ),

    // set up handler for Sector Performance chart & Key Asset Classes Performance chart

    rest.get(`${API_URL}/etf-insights/etf-data/performance/ticker-exchanges`, (req, res, ctx) => {
        const tickerExchanges = req.url.searchParams.get('ticker-exchanges');
        const keyAssetClassesTickerExchanges = 'SPY-ARCX,IWM-ARCX,AGG-ARCX,EFA-ARCX,EEM-ARCX,DBC-ARCX,GLD-ARCX';
        const sectorPerformanceTickerExchanges =
            'XLC-ARCX,XLY-ARCX,XLP-ARCX,XLE-ARCX,XLF-ARCX,XLV-ARCX,XLI-ARCX,XLB-ARCX,XLRE-ARCX,XLK-ARCX,XLU-ARCX';

        if (tickerExchanges === keyAssetClassesTickerExchanges) return res(ctx.json(keyAssetClassesPerformanceDefault));
        if (tickerExchanges === sectorPerformanceTickerExchanges) return res(ctx.json(sectorPerformanceDefault));
    }),

    // set up handler for Fund Flows chart
    rest.get(`${API_URL}/etf-insights/etf-data/flows/cfra_id/*`, (req, res, ctx) => {
        const top = req.url.searchParams.get('top');
        const start_date = req.url.searchParams.get('start_date');
        const end_date = req.url.searchParams.get('end_date');
        const frequency = req.url.searchParams.get('frequency');

        if (
            top !== null &&
            end_date !== null &&
            start_date === null &&
            Number(top) === 5 &&
            frequency === Frequencies.Daily
        )
            return res(ctx.json(flowsOneWeekDefault));

        if (start_date !== null && end_date !== null) {
            const duration = getMomentObjectFrom(end_date).diff(getMomentObjectFrom(start_date), 'months');

            if (duration === 1 && frequency === Frequencies.Daily) return res(ctx.json(flowsOneMonthDefault));
            if (duration === 3 && frequency === Frequencies.Weekly) return res(ctx.json(flowsThreeMonthDefault));
            if (duration === 6 && frequency === Frequencies.Weekly) return res(ctx.json(flowsSixMonthDefault));
            if (duration === 12 && frequency === Frequencies.Weekly) return res(ctx.json(flowsOneYearDefault));
            if (duration === 36 && frequency === Frequencies.Monthly) return res(ctx.json(flowsThreeYearsDefault));
            if (duration === 60 && frequency === Frequencies.Monthly) return res(ctx.json(flowsFiveYearsDefault));
        }

        return res(ctx.json(flowsOneWeekDefault)); // default option
    }),

    // set up handler for Flows to Assets: Themes & Factors (3 Months) chart
    rest.get(`${API_URL}/etf-insights/etf-data/aggregation/flows`, (req, res, ctx) => {
        const sortDirection = req.url.searchParams.get('sort_direction');
        if (sortDirection === 'asc') return res(ctx.json(worsePerformingFlowsDefault));
        else return res(ctx.json(bestPerformingFlowsDefault)); // default option
    }),

    // set up handler for Flows to Assets: Themes & Factors (3 Months) chart
    rest.get(`${API_URL}/etf-insights/etf-data/aggregation/flows`, (req, res, ctx) => {
        const orderBy = req.url.searchParams.get('order_by');
        const aggregateBy = req.url.searchParams.get('aggregate_by');

        switch (aggregateBy) {
            case 'asset_class':
                switch (orderBy) {
                    case 'flows_to_assets_ratio_ytd':
                        return res(ctx.json(aggregationFlowsAssetClassYtd));
                    case 'flows_to_assets_ratio_one_month':
                        return res(ctx.json(aggregationFlowsAssetClassOneMonth));
                    case 'flows_to_assets_ratio_three_month':
                        return res(ctx.json(aggregationFlowsAssetClassThreeMonth));
                    case 'flows_to_assets_ratio_one_year':
                        return res(ctx.json(aggregationFlowsAssetClassOneYear));
                }
                break;
            case 'cfra_level_1':
                switch (orderBy) {
                    case 'flows_to_assets_ratio_ytd':
                        return res(ctx.json(aggregationFlowsCfraLevelOneYtd));
                    case 'flows_to_assets_ratio_one_month':
                        return res(ctx.json(aggregationFlowsCfraLevelOneOneMonth));
                    case 'flows_to_assets_ratio_three_month':
                        return res(ctx.json(aggregationFlowsCfraLevelOneThreeMonth));
                    case 'flows_to_assets_ratio_one_year':
                        return res(ctx.json(aggregationFlowsCfraLevelOneOneYear));
                }
                break;
            case 'cfra_level_2':
                switch (orderBy) {
                    case 'flows_to_assets_ratio_ytd':
                        return res(ctx.json(aggregationFlowsCfraLevelTwoYtd));
                    case 'flows_to_assets_ratio_one_month':
                        return res(ctx.json(aggregationFlowsCfraLevelTwoOneMonth));
                    case 'flows_to_assets_ratio_three_month':
                        return res(ctx.json(aggregationFlowsCfraLevelTwoThreeMonth));
                    case 'flows_to_assets_ratio_one_year':
                        return res(ctx.json(aggregationFlowsCfraLevelTwoOneYear));
                }
                break;
        }

        const sortDirection = req.url.searchParams.get('sort_direction');
        if (sortDirection === 'asc') return res(ctx.json(worsePerformingFlowsDefault));
        else return res(ctx.json(bestPerformingFlowsDefault)); // default option
    }),

    // set up handler for Performance: Themes & Factors (3 Months) chart
    rest.get(`${API_URL}/etf-insights/etf-data/aggregation/performance`, (req, res, ctx) => {
        const orderBy = req.url.searchParams.get('order_by');
        const aggregateBy = req.url.searchParams.get('aggregate_by');

        switch (aggregateBy) {
            case 'asset_class':
                switch (orderBy) {
                    case 'split_and_dividend_ytd_return':
                        return res(ctx.json(aggregationPerformanceAssetClassYtd));
                    case 'return_split_and_dividend_one_month':
                        return res(ctx.json(aggregationPerformanceAssetClassOneMonth));
                    case 'return_split_and_dividend_three_month':
                        return res(ctx.json(aggregationPerformanceAssetClassThreeMonth));
                    case 'return_split_and_dividend_one_year':
                        return res(ctx.json(aggregationPerformanceAssetClassOneYear));
                }
                break;
            case 'cfra_level_1':
                switch (orderBy) {
                    case 'split_and_dividend_ytd_return':
                        return res(ctx.json(aggregationPerformanceCfraLevelOneYtd));
                    case 'return_split_and_dividend_one_month':
                        return res(ctx.json(aggregationPerformanceCfraLevelOneOneMonth));
                    case 'return_split_and_dividend_three_month':
                        return res(ctx.json(aggregationPerformanceCfraLevelOneThreeMonth));
                    case 'return_split_and_dividend_one_year':
                        return res(ctx.json(aggregationPerformanceCfraLevelOneOneYear));
                }
                break;
            case 'cfra_level_2':
                switch (orderBy) {
                    case 'split_and_dividend_ytd_return':
                        return res(ctx.json(aggregationPerformanceCfraLevelTwoYtd));
                    case 'return_split_and_dividend_one_month':
                        return res(ctx.json(aggregationPerformanceCfraLevelTwoOneMonth));
                    case 'return_split_and_dividend_three_month':
                        return res(ctx.json(aggregationPerformanceCfraLevelTwoThreeMonth));
                    case 'return_split_and_dividend_one_year':
                        return res(ctx.json(aggregationPerformanceCfraLevelTwoOneYear));
                }
                break;
        }

        const sortDirection = req.url.searchParams.get('sort_direction');
        if (sortDirection === 'asc') return res(ctx.json(worsePerformingPerformanceDefault));
        else return res(ctx.json(bestPerformingPerformanceDefault)); // default option
    }),

    // set up handler for Etfs Overlay modal window on market trends Themes & Factors details pages
    rest.get(`${API_URL}/etf-insights/etf-data/etfs/*`, (req, res, ctx) => {
        return res(ctx.json(etfs));
    }),
];

export const handlersForMSW = handlersForTests;
