import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fontFamilies } from 'utils';
import { fFunction } from './types';

type ButtonProps = {
    callback?: fFunction;
    text: string;
};

export function ETFRoundedButton(props: ButtonProps) {
    const theme = createTheme(createTheme(), {
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '25px',
                        width: '80%',
                        color: '#FFF',
                        fontFamily: fontFamilies.GraphikMedium,
                        fontSize: '15px',
                        backgroundColor: '#007BB8',
                        textTransform: 'lowercase',
                        letterSpacing: '1.5px',
                        borderColor: '#007BB8',
                        '&:hover': {
                            backgroundColor: '#002B5A',
                            borderColor: '#002B5A',
                        },
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Button onClick={props.callback}>{props.text}</Button>
        </ThemeProvider>
    );
}
