import { ThemeProvider, Tooltip } from '@mui/material';
import Fab from '@mui/material/Fab';
import { SxProps, styled } from '@mui/material/styles';
import { tooltipTheme } from 'components/themes/theme';
import { fontFamilies } from 'utils';
import { fFunction } from './types';

type ButtonProps = {
    callback?: fFunction;
    text: string;
    disabled?: boolean;
    isSelected: boolean;
    buttonStyles?: SxProps;
};

export const CustomFab = styled(Fab)(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#007BB8',
        color: '#ffffff',
    },
    '&:disabled': {
        backgroundColor: '#B1BCC9',
        color: '#ffffff',
    },
    fontSize: '14px',
    fontFamilies: fontFamilies.GraphikRegular,
    height: '36px',
    color: '#007BB8',
    backgroundColor: '#fff',
}));

export function ETFCircleButton(props: ButtonProps) {
    const fab = (
        <CustomFab
            size='small'
            disabled={props.disabled}
            onClick={props.callback}
            name={props.text}
            sx={
                props.isSelected
                    ? { backgroundColor: '#007BB8', color: '#ffffff', ...props.buttonStyles }
                    : { ...props.buttonStyles }
            }>
            {props.text}
        </CustomFab>
    );

    return props.disabled ? (
        <ThemeProvider theme={tooltipTheme}>
            <Tooltip title={'No Data'}>
                <span>{fab}</span>
            </Tooltip>
        </ThemeProvider>
    ) : (
        fab
    );
}
