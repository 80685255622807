import { useMediaQuery } from '@mui/material';
import { Page } from 'analytics/Page';
import OverviewCircle from 'assets/images/OverviewCircle.svg';
import { ETFCard } from 'components/layout/ETFCard';
import { ETFDivider } from 'components/layout/ETFDivider';
import Grid from 'components/layout/Grid';
import { useLayoutEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ContextType } from '../types/market';
import ETFFlowsToAssetThemesAndFactors from './charts/ETFFlowsToAssetThemesAndFactors';
import ETFPerformanceThemesAndFactors from './charts/ETFPerformanceThemesAndFactors';
import KeyAssetClasses from './charts/KeyAssetClasses';
import SectorPerformance from './charts/SectorPerformance';

export function MarketTrendsHome() {
    const isBelow1000px = useMediaQuery('(max-width:1000px)');

    const [title, setTitle] = useOutletContext<ContextType>();

    const title_text = 'Market Trends';
    useLayoutEffect(() => {
        setTitle(title_text);
    }, [setTitle]);

    function getLayout(slot1: React.ReactNode, slot2: React.ReactNode, slot3: React.ReactNode, slot4: React.ReactNode) {
        function _getLayout(xs: number) {
            return (
                <>
                    <Grid item xs={xs}>
                        {slot1}
                    </Grid>
                    <Grid item xs={xs}>
                        {slot2}
                    </Grid>
                    <Grid item xs={xs}>
                        {slot3}
                    </Grid>
                    <Grid item xs={xs}>
                        {slot4}
                    </Grid>
                </>
            );
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ETFDivider
                        imageSource={OverviewCircle}
                        header='Overview'
                        description='Track flows and performance data across detailed market sectors and emerging themes over time, all based on CFRA’s proprietary classifications. The charts below also highlight flows and performance for major asset classes and sectors, based on popular U.S. listed exchange-traded funds used to track each.'
                    />
                </Grid>

                {isBelow1000px ? _getLayout(12) : _getLayout(6)}
            </Grid>
        );
    }

    if (title !== title_text) {
        return getLayout(<ETFCard isLoading />, <ETFCard isLoading />, <ETFCard isLoading />, <ETFCard isLoading />);
    }

    return (
        <Page name='market trends'>
            {getLayout(
                <ETFFlowsToAssetThemesAndFactors />,
                <ETFPerformanceThemesAndFactors />,
                <KeyAssetClasses />,
                <SectorPerformance />,
            )}
        </Page>
    );
}
