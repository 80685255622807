import { IS_STRICT_MODE } from 'config';

export class Analytics implements IAnalytics {
    private readonly strictModeDelay = 200;
    private pageViewsTrackerIsRunable: boolean = true;
    private actionsTrackerIsRunable: boolean = true;
    constructor() {
        globalThis.cfraData = {
            pageData: {},
            actionData: {},
            siteData: {},
            userData: {},
        };

        globalThis.cfraData.siteData.siteDomain = window.location.hostname.toLowerCase();
    }

    private _registerAction(actionProps: AnalyticsActionProps) {
        globalThis.cfraData.actionData = actionProps;
        this.satelliteTrack('registerAction');
    }

    public registerAction(actionProps: AnalyticsActionProps) {
        // prevent multiple analytics calls in strinct mode
        if (IS_STRICT_MODE) {
            if (this.actionsTrackerIsRunable) {
                this.actionsTrackerIsRunable = false;
                this._registerAction(actionProps);
                setTimeout(() => (this.actionsTrackerIsRunable = true), this.strictModeDelay);
            }
        } else {
            this._registerAction(actionProps);
        }
    }

    public waitUntil(conditionFunction: () => boolean, functionsToRun: Array<() => void>): void {
        let waitUntilMaxAttempts = 100; // limit max attempts
        let interval = setInterval(function () {
            if (waitUntilMaxAttempts <= 1) {
                clearInterval(interval);
            }
            if (conditionFunction()) {
                clearInterval(interval);
                functionsToRun.forEach((func) => func());
            }
            waitUntilMaxAttempts--;
        }, 300);
    }

    private satelliteTrack(elementName: string) {
        function assignBuildDate() {
            if (globalThis._satellite.buildInfo && !cfraData.siteData.buildDate) {
                globalThis.cfraData.siteData.buildDate = globalThis._satellite.buildInfo.buildDate;
            }
        }

        this.waitUntil(
            () =>
                Boolean(
                    globalThis._satellite && globalThis._satellite.environment && globalThis._satellite.environment.id,
                ),
            [assignBuildDate, () => globalThis._satellite?.track(elementName)],
        );
    }

    private _registerPageView(pageProps: AnalyticsPageProps, clearPreviousPageProps: boolean) {
        if (clearPreviousPageProps) {
            globalThis.cfraData.pageData = pageProps;
        } else {
            globalThis.cfraData.pageData = { ...globalThis.cfraData.pageData, ...pageProps };
        }

        if (!globalThis.cfraData.pageData.group) {
            globalThis.cfraData.pageData.group = globalThis.cfraData.pageData.name;
        }
        globalThis.cfraData.pageData.pageReferrer =
            decodeURIComponent(document.referrer).toLowerCase().trim() || 'no referrer';
        globalThis.cfraData.pageData.url = decodeURIComponent(window.location.href).toLowerCase().trim();

        this.satelliteTrack('registerPageView');
    }

    public registerPageView(pageProps: AnalyticsPageProps, clearPreviousPageProps: boolean = true) {
        // prevent multiple analytics calls in strinct mode
        if (IS_STRICT_MODE) {
            if (this.pageViewsTrackerIsRunable) {
                this.pageViewsTrackerIsRunable = false;
                this._registerPageView(pageProps, clearPreviousPageProps);
                setTimeout(() => (this.pageViewsTrackerIsRunable = true), this.strictModeDelay);
            }
        } else {
            this._registerPageView(pageProps, clearPreviousPageProps);
        }
    }
}
