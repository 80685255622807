import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Grid, Typography, useMediaQuery } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { AgGrid, columnDefs } from 'components/AgGrid';
import { ChartDescription } from 'components/Chart/Chart';
import { ChartTabs, ChartTabsProps } from 'components/Chart/ChartTabs';
import { TableExportMenus } from 'components/Chart/ExportMenus';
import { exportAgGrid } from 'components/excelExport/export';
import { ETFButtonsPannel, ETFButtonsPannelButtonProps, ETFCard, ItemHeader } from 'components/layout';
import { breakpointsTheme } from 'components/themes/theme';
import { colDefType } from 'components/types/agGrid';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Categories, fontFamilies } from 'utils';
import { CategoriesInfo } from '../../components/shared/CategoriesInfo';
import { BreadcrumbConfig } from './types';
import { Link, getAsOfDateMax, getAsOfDatePath, getMinWidthForHeader, setCustomlyFlexWidths } from './utils';
import { MarketTrend } from 'features/marketTrends/types/market';

type TopPanelProps = {
    label?: string;
    description?: string;
    dateRangesPanelConfig?: Array<ETFButtonsPannelButtonProps>;
    categoriesPanelConfig?: ChartTabsProps;
    exportRef?: React.RefObject<AgGridReact<colDefType>>;
    breadcrumbsConfig?: Array<BreadcrumbConfig>;
};

type AgGirdCardProps = {
    columnDefs: columnDefs;
    getResizebleMinWidthForColumn?: (key: string) => number;
    customlyFlexibleColumns?: Array<string>;
    rowsData: Array<any>;
    maxNumberOfRowsToDisplay?: number;
    maxGridContainerHeightPercentage?: number;
} & TopPanelProps;

export function AgGirdCardTopPanel(props: TopPanelProps) {
    const { label, description, dateRangesPanelConfig, categoriesPanelConfig, breadcrumbsConfig, exportRef } = props;
    const [showExportButton, setShowExportButton] = useState(false);
    const isBelowSm = useMediaQuery(breakpointsTheme.breakpoints.down('sm'));

    useEffect(() => {
        if (exportRef && exportRef.current) {
            setShowExportButton(true);
        }
    }, [exportRef]);

    const agGridExportProps = {
        fileName:label,
        sheetName:'Data Details',
        metadata:{
            cardName: label || ''
        },
        asOfDateField: getAsOfDatePath(label as MarketTrend),
        useMaxAsOfDate: getAsOfDateMax(label as MarketTrend),
        autoSizeColumns: true,
    }

    return (
        <>
            {label || description ? (
                <Grid container sx={{ padding: '28px 28px 0px 28px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {label ? <ItemHeader sx={{ paddingBottom: '12px' }}>{label}</ItemHeader> : null}
                        {label && showExportButton ? (
                            <Grid item sx={{ display: 'flex' }}>
                                <TableExportMenus exportCallback={() => exportAgGrid(exportRef,agGridExportProps)} />
                            </Grid>
                        ) : null}
                    </Grid>
                    <Grid item xs={12}>
                        {description ? <ChartDescription>{description}</ChartDescription> : null}
                    </Grid>
                </Grid>
            ) : null}
            {dateRangesPanelConfig || categoriesPanelConfig ? (
                <Grid
                    item
                    container
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 28px', paddingTop: '0px' }}>
                    {categoriesPanelConfig ? (
                        <Grid item sx={{ paddingBottom: '36px' }} xs={isBelowSm ? 12 : undefined}>
                            <Grid container>
                                <ChartTabs
                                    {...categoriesPanelConfig}
                                    tabsStyles={{
                                        fontFamily: fontFamilies.GraphikMedium,
                                        fontSize: '15px',
                                    }}
                                />
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CategoriesInfo buttonFontSize={14} />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {dateRangesPanelConfig ? (
                        <Grid item sx={{ paddingBottom: '36px' }} xs={isBelowSm ? 12 : undefined}>
                            <ETFButtonsPannel
                                buttonsConfig={dateRangesPanelConfig}
                                buttonsStyles={{ fontSize: '15px' }}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            ) : null}
            {breadcrumbsConfig ? (
                <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                    sx={{ paddingLeft: '28px', paddingBottom: '12px' }}>
                    {breadcrumbsConfig.map((config, index) => {
                        if (index + 1 === breadcrumbsConfig.length) {
                            return <Typography key={index}>{config.text}</Typography>;
                        }
                        return <Link key={index} value={config.text} handleOpen={config.callback} />;
                    })}
                </Breadcrumbs>
            ) : null}
        </>
    );
}

export const AgGirdCard = forwardRef<AgGridReact<colDefType>, AgGirdCardProps>((props, ref) => {
    const isBelowLg = useMediaQuery(breakpointsTheme.breakpoints.down('lg'));

    const gridRef = useRef<AgGridReact<colDefType>>(null);
    useImperativeHandle(ref, () => gridRef.current!);

    let columnDefs = props.columnDefs;
    const {
        label,
        description,
        getResizebleMinWidthForColumn = getMinWidthForHeader,
        customlyFlexibleColumns = [Categories.CategoryOne, Categories.CategoryTwo, 'ETF Name'],
        rowsData,
        dateRangesPanelConfig,
        categoriesPanelConfig,
        breadcrumbsConfig,
        maxNumberOfRowsToDisplay,
        maxGridContainerHeightPercentage,
    } = props;

    if (columnDefs) {
        columnDefs = columnDefs?.map((obj) => ({
            minWidth: getMinWidthForHeader(String(obj.headerName)),
            ...obj,
        }));
    }

    return (
        <ETFCard containerStyles={{ padding: '0px' }}>
            <AgGirdCardTopPanel
                label={label}
                description={description}
                dateRangesPanelConfig={dateRangesPanelConfig}
                categoriesPanelConfig={categoriesPanelConfig}
                exportRef={gridRef}
                breadcrumbsConfig={breadcrumbsConfig}
            />
            <AgGrid
                columnDefs={columnDefs}
                getResizebleMinWidthForColumn={getResizebleMinWidthForColumn}
                updateCustomColumnsWidths={setCustomlyFlexWidths(isBelowLg, customlyFlexibleColumns)}
                rowsData={rowsData}
                ref={gridRef}
                maxNumberOfRowsToDisplay={maxNumberOfRowsToDisplay}
                maxGridContainerHeightPercentage={maxGridContainerHeightPercentage}
            />
        </ETFCard>
    );
});
