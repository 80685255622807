import {
    getSeries,
    getUniqueKeys,
    HistoricalStackedPercentage,
    sortSeriesByList,
} from 'components/Chart/HistoricalStackedPercentage';
import { getTooltipHTML } from 'components/Chart/Options';
import ChartFootnote from 'components/dataDisplay/ChartFootnote';
import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import { buildChartStacks } from 'utils';
import { calcPercentChange, formatPercentages, getExportingFileName } from 'utils/valuesFormatter';
import { getCreditExposureHistorical } from '../api/etfDetailsData';
import { ConstituentType, CreditExposureData, ETFDetailsParams } from '../types/research';

export default function CreditExposureHistorical({
    companyData,
    numberOfYears = 5,
}: {
    companyData: ETFDetailsParams;
    numberOfYears?: number;
}) {
    let creditExposureHistoricalQueryResult = getCreditExposureHistorical({
        ticker: companyData.ticker,
        exchange: companyData.exchange,
        numberOfYears: numberOfYears,
    });

    if (creditExposureHistoricalQueryResult.isLoading) {
        return <ETFCard isLoading={creditExposureHistoricalQueryResult.isLoading} />;
    }
    const cardTitle = 'Credit Exposure Over Time';
    // return EmptyCard if no data
    if (
        !(
            creditExposureHistoricalQueryResult.data &&
            creditExposureHistoricalQueryResult.data.ratings &&
            creditExposureHistoricalQueryResult.data.ratings.length > 0 &&
            creditExposureHistoricalQueryResult.data.ratings.some((x) => Number.isFinite(x.agg_weighting))
        )
    )
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;

    let creditExposureData: CreditExposureData[] = creditExposureHistoricalQueryResult.data.ratings;
    const series = getSeries(creditExposureData, ['rating', 'year_end'], 'agg_weighting').map((element) => {
        // change weightings format to percentage
        return { ...element, data: element.data.map((item) => formatPercentages(item)) };
    });
    const categories = getUniqueKeys(creditExposureData, 'year_end');
    const constituentTypes: Record<string, Array<ConstituentType>> = creditExposureData
        .filter((element) => element.constituent_types.length > 0)
        .reduce((accumulator: any, currentValue) => {
            accumulator[currentValue.year_end] = currentValue.constituent_types.map((element) => {
                return {
                    ...element,
                    // change constituent types weightings format to percentage
                    agg_weighting: formatPercentages(element.agg_weighting),
                };
            });
            return accumulator;
        }, {});

    sortSeriesByList(series, ['AAA', 'AA', 'A', 'BBB', 'BB', 'B', 'Below B', 'Not Rated', 'Other', 'Unclassified']);

    const tooltipFormatter = function (this: any): string {
        const getFormattedValue = (unformattedValue: number | null) =>
            unformattedValue !== null && unformattedValue !== 0 ? unformattedValue.toFixed(2) : unformattedValue;

        const getOtherToolTipRow = () =>
            constituentTypes[this.category].map(
                (element) => `<span>${element.type_name}: ${getFormattedValue(element.agg_weighting)}%</span>`,
            );

        const getToolTipRow = (): Array<string> => {
            let prevPoint = this.x === 0 ? null : this.series.data[this.x - 1];
            let changePercentage: string = '';

            if (prevPoint?.y) {
                changePercentage = `<span><span class="dot" /></span><span>YoY % Change: ${calcPercentChange(
                    prevPoint.y,
                    this.y,
                    2,
                )}%</span>`;
            }

            return [
                `<span><span class="dot" style="background-color: ${this.series.color}"></span></span>` +
                    `<span>${this.series.name}: ${getFormattedValue(this.y as number)}%</span>`,
                changePercentage,
            ];
        };

        return getTooltipHTML(this.category, [
            ...getToolTipRow(),
            ...(this.series.name === 'Other' ? getOtherToolTipRow() : []),
        ]);
    };

    const asOfDate = creditExposureData
        .map((item) => item.as_of_date)
        .sort()
        .reverse()[0];
    return (
        <ETFCard containerStyles={{ position: 'relative' }}>
            <HistoricalStackedPercentage
                title={cardTitle}
                subTitle='Historical credit rating data for ETF holdings by year'
                series={buildChartStacks(series)}
                categories={categories}
                exportingFileName={getExportingFileName(cardTitle, companyData.ticker, companyData.exchange)}
                tooltipFormatter={tooltipFormatter}
            />
            <DataAsOfDate date={asOfDate} />
            <ChartFootnote note='Credit rating information provided by S&P Global Ratings. Individual US sovereign securities not rated by S&P Global.' />
        </ETFCard>
    );
}
