import Axios, { AxiosRequestConfig } from 'axios';
import { syncAuth } from '../lib/authentication';
import { storage } from './storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
    const token = storage.getToken();
    if (token && config.headers) {
        config.headers['Authorization'] = `${token.tokenType} ${token.idToken}`;
    }

    return config;
}

export const axiosAuth = (url: string) => {
    const axios = Axios.create({
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    axios.interceptors.request.use(authRequestInterceptor, (error) => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    await syncAuth();
                    return axios(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
            return Promise.reject(err);
        },
    );

    return axios;
};
