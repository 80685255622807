import { ReactComponent as OpenInNew } from 'assets/images/OpenInNew.svg';
import { ETFIconButton } from './ETFIconButton';
import { ButtonProps } from './types';

export function ETFDetailsButton({ ...props }: ButtonProps) {
    const tooltipText = 'Chart Details';
    return (
        <ETFIconButton Icon={OpenInNew} tooltipText={tooltipText} name={tooltipText} buttonFontSize={30} {...props} />
    );
}
