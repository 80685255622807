/* eslint react/jsx-props-no-spreading: off */ // --> eslint says prop spreading fine for HOC

import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ETFDownloadButton } from 'components/ETFButton';
import { ETFCard, ItemHeader } from 'components/layout/ETFCard';
import Grid from 'components/layout/Grid';
import React from 'react';
import { fontFamilies } from 'utils/fonts';

type EmptyCardProps = {
    cardLabel?: string;
};

const EmptyCardItemHeader = styled(ItemHeader)(({ theme }) => ({
    paddingBottom: '0px',
    paddingTop: '6px',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    wordWrap: 'break-word',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));
const ItemDivider = styled(Divider)(({ theme }) => ({
    marginLeft: '12px',
    marginRight: '12px',
    marginTop: '6px',
    borderRight: '1px solid #4375BB',
}));
export const ItemText = styled(ItemHeader)(({ theme }) => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: 15,
    color: '#57626a',
    paddingBottom: '0px',
    paddingTop: '6px',
    paddingRight: '12px',
}));

export const defaultEmptyCardText = 'No Information Available';

export const ETFEmptyCard = React.forwardRef<HTMLDivElement, EmptyCardProps>((props, ref) => {
    const { cardLabel } = props;

    const isBelow600px = useMediaQuery('(max-width:600px)');

    return (
        <ETFCard>
            <Grid
                container
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
                wrap='nowrap'>
                {isBelow600px ? (
                    <Grid container>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <EmptyCardItemHeader>{cardLabel}</EmptyCardItemHeader>
                            <div style={{ flexGrow: 1 }} />
                            <ETFDownloadButton disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <ItemText>{defaultEmptyCardText}</ItemText>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <EmptyCardItemHeader>{cardLabel}</EmptyCardItemHeader>
                        <ItemDivider orientation='vertical' flexItem variant={'inset'} sx={{ color: 'black' }} />
                        <ItemText>{defaultEmptyCardText}</ItemText>
                        <div style={{ flexGrow: 1 }} />
                        <ETFDownloadButton disabled />
                    </>
                )}
            </Grid>
        </ETFCard>
    );
});
