import React, { useEffect } from 'react';

type PageProps = AnalyticsPageProps & {
    children?: React.ReactNode;
    clearPreviousPageProps?: boolean;
    waitUntil?: {
        condition: () => boolean;
        functionsToRun: Array<(props?: PageProps) => void | PageProps>;
    };
};

export const Page = ({ children, waitUntil, clearPreviousPageProps, ...props }: PageProps) => {
    useEffect(() => {
        const registerPageView = () => globalThis.analytics?.registerPageView?.(props, clearPreviousPageProps);
        if (waitUntil) {
            globalThis.cfraData.pageData = {};
            globalThis.analytics?.waitUntil?.(waitUntil.condition, [...waitUntil.functionsToRun, registerPageView]);
        } else {
            registerPageView();
        }
    }, [props, waitUntil, clearPreviousPageProps]);

    return <>{children}</>;
};
