import { Box } from '@mui/material';
import { ChartTitle } from 'components/Chart/Chart';
import ExportMenus, { ExportMenusForMultipleCharts } from 'components/Chart/ExportMenus';
import { HorizontalPanel } from 'components/layout';
import { MarketTrendsDetailsLink } from 'features/marketTrends/routes/MarketTrendsRoutes';
import HighchartsReact from 'highcharts-react-official';
import { ValueTypes } from 'utils/valuesFormatter';
import { MarketTrend } from '../../../types/market';

export function MarketTrendsChartsTopPanel({
    title,
    chartRef,
    detailsPathName,
    multipleExportProps,
}: {
    title: string;
    detailsPathName?: MarketTrend;
    chartRef?: React.RefObject<HighchartsReact.RefObject | null>;
    multipleExportProps?: {
        subChartsProps: Array<{
            chartRefObject?: React.RefObject<HighchartsReact.RefObject>;
            chartHeader: string;
            columnsToFormatting: Map<string, ValueTypes>;
        }>;
        exportFileName: string;
        exportHeaders?: Array<string>;
    };
}) {
    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <HorizontalPanel>
                <ChartTitle>{title}</ChartTitle>
                <div style={{ flexGrow: 1 }} />
                {detailsPathName && <MarketTrendsDetailsLink pathName={detailsPathName} />}
                {multipleExportProps ? (
                    <ExportMenusForMultipleCharts
                        multipleExport={{
                            fileName: multipleExportProps.exportFileName,
                            exportHeaders: multipleExportProps.exportHeaders,
                            chartsProps: multipleExportProps.subChartsProps.map((value) => {
                                return {
                                    chartRef: value.chartRefObject,
                                    worksheetName: value.chartHeader,
                                    columnsToFormatting: value.columnsToFormatting,
                                };
                            }),
                        }}></ExportMenusForMultipleCharts>
                ) : chartRef ? (
                    <ExportMenus chartRef={chartRef} />
                ) : null}
            </HorizontalPanel>
        </Box>
    );
}
