import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { defaultShadow } from 'components/styles/shadow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { fontFamilies } from 'utils';

type Props = {
    children?: React.ReactNode;
    removeTopMargin?: boolean;
    sx?: SxProps;
    shadow?: boolean;
};

export const HeaderName = styled(Paper)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '26px',
    color: '#0B2958',
    boxShadow: 'none',
    lineHeight: '1',
    paddingTop: '16px',
    paddingBottom: '16px',
}));

export function ETFHeaderContainer({ children, sx, shadow }: Props) {
    return (
        <Box
            sx={{
                backgroundColor: '#FFF',
                zIndex: 1100,
                ...(shadow && defaultShadow),
                ...sx,
            }}>
            <Container
                disableGutters
                maxWidth={false}
                sx={{ maxWidth: '1366px', padding: '0px 18px 0px 18px', backgroundColor: '#FFF' }}>
                {children}
            </Container>
        </Box>
    );
}
