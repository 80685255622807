import { downloadXLSX,setDisclaimer } from 'components/excelExport/export';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { ExportingMimeTypeValue } from 'highcharts/highstock';
import { ValueTypes } from 'utils/valuesFormatter';

export enum Orientations {
    vertical = 'vertical',
    horizontal = 'horizontal',
}

export type MultipleExport = {
    fileName: string;
    chartsProps: Array<{
        chartRef?: React.RefObject<HighchartsReact.RefObject>;
        worksheetName: string;
        rows?: Array<Array<string | number>>;
        columnsToFormatting: Map<String, ValueTypes>;
    }>;
    exportHeaders?: Array<string>;
};


type ExportMultipeChartsProps = {
    multipleExport: MultipleExport;
    type: string;
};

type ExportChartProps = {
    chartRef: React.RefObject<HighchartsReact.RefObject | null>;
    type: string;
};

function getSVG(
    charts: Array<HighchartsReact.RefObject>,
    options: Highcharts.Options,
    callback: (svg: string) => void,
) {
    const svgArr: Array<string> = [];
    let top: number = 0;
    let width: number = 0;

    function addSVG(svgres: string, orientation: Orientations = Orientations.horizontal) {
        // Grab width/height from exported chart
        const svgWidthRExp: RegExp = /^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/;
        const svgresWidthMatch: RegExpMatchArray | null = svgres.match(svgWidthRExp);
        if (svgresWidthMatch === null) return;
        const svgWidth: number = +svgresWidthMatch[1];

        const svgHeightRExp: RegExp = /^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/;
        const svgresHeightMatch: RegExpMatchArray | null = svgres.match(svgHeightRExp);
        if (svgresHeightMatch === null) return;
        let svgHeight: number = +svgresHeightMatch[1];

        let svg = '';

        if (orientation === Orientations.vertical) {
            // Offset the position of this chart in the final SVG
            svg = svgres.replace('<svg', `<g transform="translate(0,${top})" `);
            svg = svg.replace('</svg>', '</g>');
            top += svgHeight;
            width = Math.max(width, svgWidth);
        } else if (orientation === Orientations.horizontal) {
            // for horizontal orientation
            svg = svgres.replace('<svg', `<g transform="translate(${width}, 0)" `);
            svg = svg.replace('</svg>', '</g>');
            width += svgWidth;
            top = Math.max(top, svgHeight);
        }

        svgArr.push(svg);
    }

    function exportChart(i: number) {
        if (i === charts.length) {
            return callback(
                '<svg height="' +
                    top +
                    '" width="' +
                    width +
                    '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
                    svgArr.join('') +
                    '</svg>',
            );
        }

        addSVG(charts[i].chart.getSVG(options));
        exportChart(i + 1);
    }
    exportChart(0);
}

function exportChartsAsImage(charts: Array<HighchartsReact.RefObject>, options: Highcharts.Options) {
    options = Highcharts.merge(Highcharts.getOptions().exporting, options);

    // Get SVG asynchronously and then download the resulting SVG
    getSVG(charts, options, function (svg) {
        Highcharts.downloadSVGLocal(svg, options.exporting ? options.exporting : {}, function () {
            console.log('Failed to export on client side');
        });
    });
}

function parseString(input: string): Array<Array<string>> {
    return input?.split('\n').map((element) => element.split(',').map((element) => element.replaceAll('"', '')));
}

const formatValue = (value: any, type: ValueTypes | undefined) => {
    if (type === ValueTypes.Numeral) return Number(value);
    return value;
};

const typifyValues = (
    table: Array<Array<string>>,
    columnsToFormatting: Map<String, ValueTypes>,
): Array<Array<string | number>> => {
    table[0].forEach((columnName, columnIndex) => {
        if (columnsToFormatting.has(String(columnName))) {
            table?.forEach((item, itemIndex) => {
                // skip header
                if (itemIndex === 0) return;
                // change value format
                if (item[columnIndex])
                    item[columnIndex] = formatValue(item[columnIndex], columnsToFormatting.get(String(columnName)));
            });
        }
    });
    return table;
};

const exportChartsAsImageOfType = (
    charts: Array<React.RefObject<HighchartsReact.RefObject> | undefined>,
    type: ExportingMimeTypeValue,
    fileName: string,
): void => {
    const filteredCharts = charts
        .map((element) => element?.current)
        .filter((element) => element !== undefined) as Array<HighchartsReact.RefObject>;
    exportChartsAsImage(filteredCharts, {
        exporting: {
            type: type,
            filename: fileName,
        },
    });
};

const exportExcelForMultipleCharts = async (exportToExcelProps: MultipleExport) => {
    // Since the Library size is so large, we should only download when requested
    // eslint-disable-next-line
    import('exceljs').then(async (ExcelJS) => {
        // Create workbook

        let workbook = new ExcelJS.Workbook();
        exportToExcelProps.chartsProps?.forEach((element) => {
            // Add Data Worksheet
            const worksheet = workbook.addWorksheet(element.worksheetName);
            if (exportToExcelProps.exportHeaders && exportToExcelProps.exportHeaders.length > 0) {
                if (element?.rows) element.rows[0] = exportToExcelProps.exportHeaders;
            }
            // write worksheet rows if
            if (element?.rows) worksheet.addRows(element?.rows);
        });
        downloadXLSX(setDisclaimer(workbook), `${exportToExcelProps.fileName}`);
    });
};


export const exportMultipleCharts = ({ multipleExport, type }: ExportMultipeChartsProps): void => {
    switch (type) {
        case 'xlsx':
            multipleExport.chartsProps.forEach((element) => {
                // receive string in csv format
                const csvString = element.chartRef?.current?.chart.getCSV();
                if (!csvString) return;
                // parce csv string
                element.rows = parseString(csvString);
                if (!element.rows || element.rows.length <= 1) return;
                // typify values
                if (element.columnsToFormatting)
                    element.rows = typifyValues(element.rows as Array<Array<string>>, element.columnsToFormatting);
                // format values corresponds with settings in element.columnsToFormatting
            });
            // export xlsx file
            exportExcelForMultipleCharts(multipleExport);
            break;
        case 'jpeg':
        case 'png':
        case 'svg+xml':
            exportChartsAsImageOfType(
                multipleExport.chartsProps.map((element) => element.chartRef),
                `image/${type}`,
                multipleExport.fileName,
            );
            break;
        case 'pdf':
            exportChartsAsImageOfType(
                multipleExport.chartsProps.map((element) => element.chartRef),
                `application/${type}`,
                multipleExport.fileName,
            );
            break;
    }
};


export const exportChart = ({ chartRef, type }: ExportChartProps): void => {
    switch (type) {
        case 'csv':
            import('exceljs').then(async (ExcelJS) => {
                const workbook = new ExcelJS.Workbook()
                const csvString = chartRef?.current?.chart?.getCSV();
                if (!csvString) return;
                const fileName = chartRef?.current?.chart?.getFilename();
                let dataRow = parseString(csvString);
                if (exportHasDuplicateColumns(dataRow[0])){
                    dataRow = mergeDuplicateColumns(dataRow);
                }
                const worksheet = workbook.addWorksheet(fileName);
                dataRow.forEach((row)=>{
                    worksheet.addRow(row)
                })
                downloadXLSX(setDisclaimer(workbook),`${fileName}`);
            })
            break;
        case 'jpeg':
            chartRef?.current?.chart.exportChart({ type: `image/${type}` }, {});
            break;
    }
};
function mergeDuplicateColumns(dataRows: Array<Array<string>>): Array<Array<string>> {
    let headers = dataRows.shift() || [];
    let newArray: Array<Array<string>> = [Array.from(new Set(headers))];


    for (let i = 0; i < dataRows.length; i++) {
        let mergeArray: Array<string> = [];

        for (let j = 0; j < dataRows[i].length; j++) {
            if (j < headers.length && headers[j] === headers[j + 1]) {
                mergeArray.push(dataRows[i][j] === '' ? dataRows[i][j + 1] : dataRows[i][j]);
            } else if (headers[j] !== headers[j - 1]) {
                mergeArray.push(dataRows[i][j]);
            }
        }
        newArray.push(mergeArray);
    }
    return newArray;
}


function exportHasDuplicateColumns(headers:Array<string>):boolean{
    return new Set(headers).size!==headers.length;
}