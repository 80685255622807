import { axios } from 'lib/axios-client';

import { UserResponse } from '../types';

export type RegisterCredentialsDTO = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
};

export const registerWithEmailAndPassword = (data: RegisterCredentialsDTO): Promise<UserResponse> =>
    axios.post('/auth/register', data);
