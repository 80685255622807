import { createContext, useState } from 'react';
import { animateScroll } from 'react-scroll';

export interface ScrollingStateProps {
    goingUp: boolean;
    setGoingUp: React.Dispatch<React.SetStateAction<boolean>>;
    activeTab: number;
    setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const options = {
    duration: 400,
    delay: 100,
    smooth: true,
};

export const animateScrollTo = (y: number) => {
    animateScroll.scrollTo(y, options);
};

export const animateScrollToTop = () => {
    animateScroll.scrollToTop(options);
};

const ScrollingContext = createContext<ScrollingStateProps>({} as ScrollingStateProps);

interface Props {
    children: React.ReactNode;
}

const ScrollingContextProvider: React.FC<Props> = ({ children }) => {
    const [goingUp, setGoingUp] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    return (
        <ScrollingContext.Provider value={{ goingUp, setGoingUp, activeTab, setActiveTab }}>
            {children}
        </ScrollingContext.Provider>
    );
};

export { ScrollingContext, ScrollingContextProvider };
