import { SelectChangeEvent } from '@mui/material';
import { Categories, MarketTrendsDateRanges } from 'utils';

export const dataLimitForAssetCategory: number = 10;

export function getChangeEvents(
    setSelectedCategory: (value: Categories) => void,
    setSelectedDateRange: (value: MarketTrendsDateRanges) => void,
) {
    return {
        handleCategoryChange: (_event: React.SyntheticEvent<Element, Event>, newValue: number) => {
            setSelectedCategory(Object.values(Categories)[newValue]);
        },
        handleDateRangeChange: (event: SelectChangeEvent<unknown>) => {
            setSelectedDateRange(event.target.value as MarketTrendsDateRanges);
        },
    };
}
