import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import logo from '../../assets/images/CFRA White Logo.svg';
import './Footer.scss';
import Tooltip from 'components/dataDisplay/Tooltip';
import { Stack, Box, ThemeProvider } from '@mui/material';
import { tooltipThemeV2 } from 'components/themes/theme';
import { ScrollingContext, animateScrollToTop } from 'utils/scrolling';
import { useContext } from 'react';

const Footer = () => {
    const { setGoingUp, setActiveTab } = useContext(ScrollingContext);
    return (
        <div className='etf-insights-footer'>
            <div className='footer-wrapper'>
                <ThemeProvider theme={tooltipThemeV2}>
                    <Tooltip title='Back to Top' placement='top-start' arrow>
                        <button
                            className='btn'
                            onClick={() => {
                                setGoingUp(true);
                                setActiveTab(0);
                                animateScrollToTop();
                            }}>
                            <ArrowUpwardIcon />
                        </button>
                    </Tooltip>
                </ThemeProvider>
                <Stack
                    sx={{
                        py: 4.5,
                        px: 2.5,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '@media (max-width:700px)': {
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        },
                    }}>
                    <img src={logo} className='logo' alt='logo' />
                    <Stack
                        sx={{ pt: 0, '@media (max-width:700px)': { pt: 1.5 } }}
                        direction='row'
                        alignItems='center'
                        spacing={2}>
                        <a className='a1' href='https://www.cfraresearch.com/legal/'>
                            Legal Notice and Privacy Policy
                        </a>
                        <hr className='vr' />
                        <a className='a2' href='https://www.cfraresearch.com/contact/'>
                            Contact Us
                        </a>
                    </Stack>
                </Stack>
            </div>
            <div className='year'>
                <Box
                    sx={{
                        height: '36px',
                        backgroundColor: '#f2f5fc',
                        color: '#76818c',
                        fontSize: '14px',
                        fontFamily: 'GraphikRegular',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        '@media (max-width:700px)': { height: 'auto' },
                    }}>
                    <p>
                        {' '}
                        Copyright © {new Date().getFullYear()} CFRA.{' '}
                        <span>
                            All rights reserved. All trademarks mentioned herein belong to their respective owners.
                        </span>{' '}
                    </p>
                </Box>

                <hr className='divider'></hr>
            </div>
        </div>
    );
};

export default Footer;
