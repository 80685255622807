import { Typography } from '@mui/material'; 

type ChartFootnoteProps = {
note: string;
}

const ChartFootnote = (Props: ChartFootnoteProps) => {
    return(
        <Typography variant='body2' color='#999' fontSize='11px' fontFamily='GraphikRegular' paddingTop='16px' margin='0'>
                    {Props.note}
                  </Typography>
    )
} 

export default ChartFootnote;