import ETFModal from 'components/ETFModal';
import { ETFCard, ETFLinkButton, ItemDescription } from 'components/layout';
import Grid from 'components/layout/Grid';

import React, { useCallback } from 'react';

export default function FundDetail(props: { detail: string }) {
    const getOpenModalButton = useCallback((handleOpen: () => void) => {
        return <ETFLinkButton onClick={handleOpen} text='... Read More' />;
    }, []);

    const getCardDetails = (fundDetail: string): React.ReactNode => {
        return (
            <ItemDescription>
                {fundDetail.length <= 500 ? (
                    fundDetail
                ) : (
                    <>
                        {fundDetail.substring(0, fundDetail.lastIndexOf(' ', 500) + 1)}
                        <ETFModal
                            getOpenComponent={getOpenModalButton}
                            title={'Fund Details'}
                            description={fundDetail}
                        />
                    </>
                )}
            </ItemDescription>
        );
    };

    return (
        <ETFCard cardLabel='Fund Details'>
            <Grid item xs={12}>
                {getCardDetails(props.detail)}
            </Grid>
        </ETFCard>
    );
}
