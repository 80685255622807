

import { Box } from "@mui/material";
import Typography from "components/dataDisplay/Typography";
import Grid from "components/layout/Grid";
import { FeatureCard } from "../FeatureCard";

type FeaturesProps = {
    title: string;
    features: {
        img: string;
        text: string;
    }[];
};

export function FeatureList(props: FeaturesProps) {
    const { title, features } = props;
    return (
        <Box component="div">
            <Typography variant="h2" textAlign='center' paddingBottom='40px'>{title}</Typography>
            <Grid item container spacing={6} justifyContent="center">
                {features.map((item) => (
                    <Grid key={item.text} item sm={12} md={4} display="flex" justifyContent="center">
                        <FeatureCard img={item.img} text={item.text} sx={{ padding: '32px 28px', maxWidth: '320px' }} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};